<template>
  <div class="settled sflex-y">
    <div class="settled-title">
      <img src="../assets/images/settled_title.png" />
    </div>
    <div class="settled-content-bg"></div>
    <div class="settled-content">
      <div class="form-content">
        <el-form :model="form" label-width="80px">
          <el-form-item label="姓名：">
            <el-input
              v-model="form.teacherName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在学校：">
            <el-input
              v-model="form.school"
              placeholder="请输入所在学校"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：">
            <el-input
              v-model="form.phoneNumber"
              placeholder="请输入联系电话"
              class="phone"
            >
              <template slot="append">
                <div>
                  <span class="get-code-line">|</span
                  ><span
                    id="getPhoneCode"
                    ref="getPhoneCode"
                    :style="{ cursor: time !== 61 ? 'no-drop' : 'pointer' }"
                    @click="checkPhoneCode"
                    >{{ phoneCodeTxt }}</span
                  >
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="输入验证码：">
            <el-input
              v-model="form.phoneCode"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="form.remark" placeholder="选填"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="settled-button-content">
        <div>
          <el-button class="settled-button" @click="addTeacher">提交</el-button>
        </div>
        <div class="settled-button-label">
          <div class="settled-button-label-text sflex">
            <div class="settled-button-label-number">1</div>
            <div>
              申请者需为公办高校教师，双一流、985、211等高校在站博士后、在读博士等优秀学生；
            </div>
          </div>
          <div class="settled-button-label-text sflex">
            <div class="settled-button-label-number">2</div>
            <div>收到申请后 ，我们将在5个工作日内与您联系。</div>
          </div>
          <div class="settled-button-label-text sflex">
            <div class="settled-button-label-number">3</div>
            <div>
              您也可以直接发“姓名+所在高校+联系手机”至contact@zeersheng.com。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Settled',
  data() {
    return {
      form: {
        teacherName: '',
        school: '',
        phoneNumber: '',
        remark: '',
        phoneCode: ''
      },
      phoneCodeTxt: '获取验证码',
      time: 61 // 倒计时
    }
  },
  methods: {
    // 获取验证码
    checkPhoneCode() {
      if (this.form.phoneNumber === '' || this.form.phoneNumber === null) {
        this.$message({
          message: '请输入联系电话！',
          type: 'warning'
        })
        return
      }
      if (this.time !== 61) return
      if (this.checkPhone()) {
        let obj = { 'phone': this.form.phoneNumber }
        this.$apis.checkPhoneCode(obj).then(response => {
          if (response.data.code === 200) {
            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success'
            })

            let that = this
            that.time = 61
            that.time--
            let timer = setInterval(function () {
              if (that.time > 1) {
                that.time--
                that.phoneCodeTxt = `${that.time}s后重新获取`
              } else {
                clearInterval(timer)
                that.time = 61
                that.phoneCodeTxt = '获取验证码'
              }
            }, 1000)
          } else {
            this.$message({
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }
    },

    // 提交教师申请
    addTeacher() {
      if (this.form.teacherName === '' || this.form.teacherName === null) {
        this.$message({
          message: '请输入您的姓名！',
          type: 'warning'
        })
        return
      }
      if (this.form.school === '' || this.form.school === null) {
        this.$message({
          message: '请输入所在学校！',
          type: 'warning'
        })
        return
      }
      if (this.form.phoneNumber === '' || this.form.phoneNumber === null) {
        this.$message({
          message: '请输入联系电话！',
          type: 'warning'
        })
        return
      }
      if (this.checkPhone()) {
        if (this.form.phoneCode === '' || this.form.phoneCode === null) {
          this.$message({
            message: '请输入验证码！',
            type: 'warning'
          })
          return
        }
        this.$apis.addTeacher(this.form).then(response => {
          if (response.data.code === 200) {
            this.$message({
              message: '申请成功，我们将在5个工作日内与您联系。',
              type: 'success'
            })
          }
        })
      }
    },

    // 校验手机号码
    checkPhone() {
      let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      // var url="/nptOfficialWebsite/apply/sendSms?phoneNumber="+this.ruleForm.phone;
      if (this.phone === '') {
        this.$message({
          message: '请输入联系电话！',
          type: 'warning'
        })
        return false
      } else if (!reg.test(this.form.phoneNumber)) {
        this.$message({
          message: '手机格式不正确',
          type: 'warning'
        })
        return false
      }
      return true
    }
  }
}
</script>
<style lang="less">
.settled {
  align-items: center;
  .settled-title {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .settled-content-bg {
    width: 53.9rem;
    height: 5rem;
    box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.09);
    opacity: 1;
    border-radius: 1rem;
    margin-top: -4.15rem;
  }
  .settled-content {
    width: 53.9rem;
    // height: 27.15rem;
    box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.09);
    border-radius: 1rem;
    background: #ffffff;
    margin-top: -4.7rem;
    padding: 2.1rem 5.54rem 2.1rem 6.15rem;
    margin-bottom: 1.5rem;
    .el-form-item {
      margin-bottom: 0.8rem;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
    .el-form-item__label {
      font-size: 0.9rem;
      width: 5.5rem !important;
      font-weight: 400;
      color: #333333;
      text-align: right;
      padding-right: 0;
    }
    .el-form-item__content {
      width: 34rem;
      height: 2.2rem;
      border: 0.05px solid #e3e3e3;
      box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.04);
      border-radius: 0.25rem;
      margin-left: 5.5rem !important;
      .el-input__inner {
        padding-left: 0.95rem;

        width: 34rem;
        height: 2.2rem;
        // border: 0.05px solid #e3e3e3;
        box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.04);
        border-radius: 0rem;
      }
      .phone {
        .el-input__inner {
          width: calc(34rem - 7.85rem - 1px);
        }
        .el-input-group__append {
          width: 8rem;
          height: calc(2.2rem - 2px);
          font-size: 0.8rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4762f6;
          // z-index: -1;
          // line-height: 3rem;
          padding: 0 0.85rem;
          background-color: rgba(0, 0, 0, 0) !important;
          box-sizing: border;
          // border: 1px solid #e3e3e3 !important;
          border: none !important;
          top: 1px;
          .get-code-line {
            margin-right: 0.85rem;
          }
        }
      }
    }
    .settled-button-content {
      padding: 1.35rem 5.35rem 0rem 5.45rem;
      text-align: left;
      .settled-button {
        width: 6.6rem;
        height: 2.05rem;
        background: linear-gradient(90deg, #425df6, #6a81f8);
        box-shadow: 0rem 0rem 0rem 0rem rgba(71, 98, 248, 0.46);
        border-radius: 0.25rem;
        font-size: 0.9rem;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #f6f7f9;
        // line-height: 2.05rem;
      }
      .settled-button-label {
        width: 37rem;
        margin-top: 2.05rem;
        .settled-button-label-text {
          color: #435ef6;

          font-size: 0.9rem;
          margin-bottom: 0.75rem;
          .settled-button-label-number {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            font-size: 0.7rem;
            font-family: Microsoft YaHei;
            background: #6980f8;
            font-weight: 400;
            color: #ffffff;
            padding: 0 0.4rem;
            display: block;
            margin-right: 0.4rem;
            line-height: 1.2rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .settled {
    .settled-content-bg {
      width: 34.9rem;
    }
    .settled-content {
      width: 34.9rem;
      padding: 2.1rem 2.54rem 2.1rem 2.15rem;
      .el-form-item__content {
        width: 24rem;
        .el-input__inner {
          width: 24rem;
        }
        .phone {
          .el-input__inner {
            width: calc(24rem - 7.85rem - 1px);
          }
        }
      }
      .settled-button-content {
        .settled-button-label {
          width: 24rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .settled {
    .settled-content-bg {
      width: 24.9rem;
      margin-top: -2rem;
    }
    .settled-content {
      width: 24.9rem;
      padding: 2.1rem 2.54rem 2.1rem 2.15rem;
      .el-form-item__content {
        width: 14rem;
        .el-input__inner {
          width: 14rem;
        }
        .phone {
          .el-input__inner {
            width: calc(14rem - 7.85rem - 1px);
          }
        }
      }
      .settled-button-content {
        padding: 1.35rem 2rem 0rem 0rem;
        text-align: left;

        .settled-button-label {
          width: 21rem;
        }
      }
    }
  }
}
</style>
