<template>
  <div class="choose-major-modal">
    <div class="choose-major-head sflex sflex-ac sflex-jc">
      <!-- <input
        v-model="searchValue"
        type="text"
        :placeholder="subjectTypeStr"
        class="choose-major-inp"
      /> -->
      <el-select
        v-model="searchValue"
        filterable
        clearable
        :placeholder="subjectTypeStr"
        class="choose-major-inp"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in allSchoolList"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <div class="search-btn" @click="getSearch()">查询</div>
    </div>
    <div class="choose-major-main">
      <div class="main-bread sflex sflex-ac">
        <img src="../assets/images/choose_major_home.png" alt="" />
        <div>
          <span class="hand" @click="goToIndex">首页</span> >
          <span class="hand" @click="goToMajor">选专业</span> >
          <span class="hand">按学校找老师（{{ subjectTypeStr }}）</span>
          <span v-if="currentSchoolName != ''">
            <span>>&nbsp;</span>
            <span class="active hand">{{ currentSchoolName }}</span>
          </span>
        </div>
      </div>
      <div class="main sflex sflex-jsp">
        <div class="main-left">
          <div class="left-title sflex sflex-ac sflex-jc">
            按学校找老师（{{ subjectTypeStr }}）
          </div>
          <div class="left-content">
            <div v-for="(item, x) in areaList" :key="x" class="left-item">
              <div
                class="item-title ut-s"
                :class="{ active: item.isCheck }"
                @click="getSchool(item, x)"
              >
                {{ item.areaName }}
              </div>
              <div v-if="item.childList.length > 0" class="item-content-box">
                <div
                  v-for="(childItem, b) in item.childList"
                  :key="b"
                  class="item-content ut-s"
                  :class="{ active: childItem.isCheck }"
                  @click="getSchoolDetails(childItem)"
                >
                  {{ childItem.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div
            v-if="isSchoolDetails"
            ref="schooltitle"
            class="major-school-title sflex sflex-jsp"
          >
            <div class="sflex sflex-ac sflex-grow">
              <div class="school-img">
                <img :src="schoolDetails.logo" class="fit-img-cover" alt="" />
              </div>
              <div class="school-introduce">
                <div class="sflex sflex-ae school-introduce-name">
                  <div class="name">{{ schoolDetails.name }}</div>
                  <div class="sflex sflex-ac content-location">
                    <img
                      src="../assets/images/major_location.png"
                      class="location-icon"
                      alt=""
                    />
                    <div class="ut-s2">{{ schoolDetails.address }}</div>
                  </div>
                </div>
                <div class="slogan">
                  校训：<span class="text-blue">{{ schoolDetails.motto }}</span>
                </div>
                <div class="school-rank sflex sflex-ac sflex-wrap">
                  <div
                    v-for="(levelItem, z) in schoolDetails.levelArr"
                    :key="z"
                    class="rank-item"
                  >
                    {{ levelItem }}
                  </div>
                  <!-- <div class="blue">双一流</div>
                        <div class="pink">985</div>
                        <div class="green">211</div>
                        <div class="purple">公办</div> -->
                </div>
              </div>
            </div>
            <div class="school-details sflex sflex-ac sflex-grow sflex-jsp">
              <div class="details-item sflex sflex-ac sflex-jc sflex-y hand">
                <div class="icon-box sflex sflex-ac sflex-jc">
                  <img
                    class="icon-no-active"
                    src="../assets/images/school_icon_5.png"
                    alt=""
                  />
                  <img
                    class="icon-active"
                    src="../assets/images/school_icon_9.png"
                    alt=""
                  />
                </div>
                学校官网
              </div>
              <div
                class="details-item sflex sflex-ac sflex-jc sflex-y hand"
                :class="{ active: isSpecialty }"
              >
                <div class="icon-box sflex sflex-ac sflex-jc">
                  <img
                    class="icon-no-active"
                    src="../assets/images/school_icon_6.png"
                    alt=""
                  />
                  <img
                    class="icon-active"
                    src="../assets/images/school_icon_10.png"
                    alt=""
                  />
                </div>
                学院设置
              </div>
              <div
                class="details-item sflex sflex-ac sflex-jc sflex-y hand"
                :class="{ active: isAcademy }"
              >
                <div class="icon-box sflex sflex-ac sflex-jc">
                  <img
                    class="icon-no-active"
                    src="../assets/images/school_icon_7.png"
                    alt=""
                  />
                  <img
                    class="icon-active"
                    src="../assets/images/school_icon_11.png"
                    alt=""
                  />
                </div>
                开设专业
              </div>
              <div
                class="details-item sflex sflex-ac sflex-jc sflex-y hand"
                :class="{ active: isScore }"
              >
                <div class="icon-box sflex sflex-ac sflex-jc">
                  <img
                    class="icon-no-active"
                    src="../assets/images/school_icon_8.png"
                    alt=""
                  />
                  <img
                    class="icon-active"
                    src="../assets/images/school_icon_12.png"
                    alt=""
                  />
                </div>
                历年高考数据
              </div>
            </div>
            <!-- 开设专业开始 -->
            <div
              v-show="isSpecialty"
              class="set-up-specialty-model"
              :style="{ top: schooltitleHeight }"
            >
              <div class="close" @click="toggerSchoolDetails(0)"></div>
              <div class="specialty-head">
                <div class="head-left sflex sflex-ac">国家特色专业</div>
                <div class="head-right">
                  新闻学 核工程与核技术 汉语言文学 数学与应用数学 轻化工程
                  法医学 历史学 生物科学 高分子材料与工程 药学
                  机械设计制造及其自动化 哲学 护理学 制药工程 土木工程
                  医学信息工程 计算机科学与技术 英语 经济学 基础医学 化学
                  新能源材料与器件
                </div>
              </div>
              <div class="specialty-search sflex sflex-je">
                <div class="sflex sflex-ac inp-box">
                  <img
                    src="../assets/images/major_search.png"
                    class="inp-icon"
                    alt=""
                  />
                  <input
                    id=""
                    class="search-inp"
                    type="text"
                    name=""
                    placeholder="请输入专业名称"
                  />
                </div>
                <div class="search-btn">搜索</div>
              </div>
              <div class="specialty-table">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column
                    prop="name"
                    label="国家特色专业"
                    width="200"
                    header-align="left"
                    align="center"
                    label-class-name="specialty-table-title"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="amount1"
                    label="专业名称"
                    header-align="center"
                    label-class-name="specialty-table-title"
                    class-name="specialty-namelist"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 开设专业结束 -->

            <!-- 学院设置开始 -->
            <div
              v-show="isAcademy"
              class="academy-model"
              :style="{ top: schooltitleHeight }"
            >
              <div class="close" @click="toggerSchoolDetails(1)"></div>
              <div class="academy-list sflex sflex-ac sflex-wrap">
                <div
                  v-for="academyIdx in 8"
                  :key="academyIdx"
                  class="academy-item sflex sflex-ac"
                >
                  <div class="dot"></div>
                  <div class="ut-s">经济学院</div>
                </div>
              </div>
            </div>
            <!-- 学院设置结束 -->

            <!-- 历年高考数据开始 -->
            <div
              v-show="isScore"
              class="score-model"
              :style="{ top: schooltitleHeight }"
            >
              <div class="close" @click="toggerSchoolDetails(2)"></div>
              <div class="fraction-line">
                <div class="line-header sflex sflex-jsp">
                  <div class="line-title sflex sflex-ac">
                    <div class="line"></div>
                    省份分数线
                  </div>
                  <div class="line-select">
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        河北<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        2021<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        物理类<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="line-table">
                  <el-table :data="tableData" style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="年份"
                      width="200"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="录取批次"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="招生类型"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="最低分/最低位次"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="省控线"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                  </el-table>
                </div>
                <div class="line-more sflex sflex-je">问问招办，了解更多>></div>
              </div>
              <div class="score-plan">
                <div class="sflex sflex-ac sflex-jsp">
                  <div class="line-title sflex sflex-ac">
                    <div class="line"></div>
                    招生计划
                  </div>
                  <div class="line-select">
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        河北<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        2021<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        物理类<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        本科类<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="plan-tips">
                  <span>*</span> 预计更新时间为2022年6~7月份。
                </div>
                <div class="line-table">
                  <el-table :data="tableData" border style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="专业名称"
                      width="300"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="计划招生"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="学制"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="学费"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="选科要求"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                  </el-table>
                  <div class="score-pagination sflex sflex-je">
                    <div class="block">
                      <el-pagination
                        :page-size="10"
                        layout="prev, pager, next"
                        :total="5"
                        @current-change="handleCurrentChange"
                      >
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div class="score-plan">
                <div class="line-header sflex sflex-ac sflex-jsp">
                  <div class="line-title sflex sflex-ac">
                    <div class="line"></div>
                    专业分数线
                  </div>
                  <div class="line-select">
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        河北<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        2021<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        物理类<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown>
                      <span class="el-dropdown-link select-item">
                        本科类<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>黄金糕</el-dropdown-item>
                        <el-dropdown-item>狮子头</el-dropdown-item>
                        <el-dropdown-item>螺蛳粉</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="line-table">
                  <el-table :data="tableData" border style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="专业名称"
                      width="300"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="录取批次"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="平均分"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="最低分/最低位次"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="amount1"
                      label="选科要求"
                      align="center"
                      label-class-name="specialty-table-title"
                    >
                    </el-table-column>
                  </el-table>
                  <div class="score-pagination sflex sflex-je">
                    <div class="block">
                      <el-pagination
                        :page-size="10"
                        layout="prev, pager, next"
                        :total="10"
                        @current-change="handleCurrentChange"
                      >
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 历年高考数据结束 -->
          </div>
          <div
            v-if="teacherLoopList && teacherLoopList.length > 0"
            class="teacher-loop-model"
          >
            <div class="teacher-swiper" ref="teacherSwiper">
              <div class="swiper-wrapper">
                <div
                  v-for="(item, teacherIdx) in teacherLoopList"
                  :key="teacherIdx"
                  class="swiper-slide"
                  @click="getThisTeacher(item.id, item)"
                >
                  <img
                    class="icon-active"
                    :src="
                      item.avatar
                        ? item.avatar
                        : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                    "
                    alt=""
                  />
                  <div>{{ item.realName }}{{ item.teacherTitle }}</div>
                </div>
              </div>
              <div class="swiper-button-prev teacher-prev"></div>
              <div class="swiper-button-next teacher-next"></div>
            </div>
          </div>
          <div v-for="(item, i) in dataShow" :key="i">
            <div class="right-content">
              <div class="main-title sflex sflex-ac sflex-jsp">
                <div class="taecher-content sflex sflex-as sflex-grow">
                  <div class="content-img-box sflex sflex-ac sflex-y">
                    <div class="head-img">
                      <img
                        class="fit-img-cover"
                        :src="
                          item.avatar
                            ? item.avatar
                            : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                        "
                        alt=""
                      />
                    </div>
                    <div class="title-img">
                      <img
                        class="fit-img-cover"
                        src="../assets/images/teacher_title.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="teac-synopsis">
                    <div class="sflex sflex-ac">
                      <div class="teac-name ut-s">
                        {{ item.realName }}{{ item.teacherTitle }}
                      </div>
                      <div
                        v-for="(consultModeItem, n) in item.consultMode"
                        :key="n"
                        class="teac-attr"
                      >
                        {{
                          consultModeItem == '1'
                            ? '图文'
                            : consultModeItem == '2'
                            ? '电话'
                            : '线下'
                        }}
                      </div>
                    </div>
                    <div class="sflex sflex-ac teac-location">
                      <img
                        src="../assets/images/major_location.png"
                        class="location-icon"
                        alt=""
                      />
                      <div class="ut-s2">
                        {{ item.provinceName }}{{ item.cityName }}
                      </div>
                    </div>
                    <div class="teac-txt">
                      {{ item.teacherName }}<br />
                      {{ item.teacherDescribe }}
                    </div>
                  </div>
                  <div class="teac-classify sflex sflex-ac sflex-wrap">
                    <!-- <div class="classify-item">专业咨询</div> -->
                    <div
                      v-for="(tagItem, r) in item.tagArr"
                      :key="r"
                      class="classify-item"
                    >
                      {{ tagItem }}
                    </div>
                  </div>
                </div>
                <div class="main-btn">扫码咨询</div>
              </div>
              <div class="main-content sflex">
                <div class="content-left">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">教育（工作）背景</div>
                  </div>
                  <div class="details">
                    <div
                      v-for="(expItem, j) in item.exp"
                      :key="j"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        {{ expItem }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-center">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">线上咨询</div>
                  </div>
                  <div class="details">
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">专业：</span>
                        <br />
                        <div
                          v-for="(specialityItem, v) in item.specialityList"
                          :key="v"
                        >
                          <span class="text-blue">
                            {{ specialityItem.code
                            }}{{ specialityItem.categoryName }}：
                          </span>
                          <span
                            v-for="(
                              specialityChildItem, k
                            ) in specialityItem.childList"
                            :key="k"
                            >{{ specialityChildItem.categoryName }}
                            <span
                              v-if="specialityItem.childList.length - 1 > k"
                              class="text-blue"
                              >/&nbsp;</span
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">学校：</span>
                        <span class="text-tips">(个人观点，非官方)</span>
                        <br />
                        <div v-if="item.schoolArr.length > 0">
                          <div
                            v-for="(schoolItem, e) in item.schoolArr"
                            :key="e"
                          >
                            <span class="text-blue">
                              {{ schoolItem.name }}：
                            </span>
                            <span
                              v-for="(
                                schoolChildItem, u
                              ) in schoolItem.collegeArr"
                              :key="u"
                              >{{ schoolChildItem.name }}
                              <span
                                v-if="
                                  schoolChildItem.subjectTypeList.length > 0
                                "
                              >
                                <span>（</span>
                                <span
                                  v-for="(
                                    subjectTypeItem, p
                                  ) in schoolChildItem.subjectTypeList"
                                  :key="p"
                                >
                                  <span>{{
                                    subjectTypeItem.id === '1'
                                      ? '高考'
                                      : subjectTypeItem.id === '2'
                                      ? '考研'
                                      : subjectTypeItem.id === '3'
                                      ? '考博'
                                      : '暂无'
                                  }}</span>
                                  <span
                                    v-if="
                                      schoolChildItem.subjectTypeList.length -
                                        1 >
                                      p
                                    "
                                    class="text-blue"
                                    >&nbsp;/&nbsp;</span
                                  ></span
                                >
                                <span>）</span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        item.zerConsultScopeList &&
                        item.zerConsultScopeList.length > 0
                      "
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">其他：</span>
                        <br />
                        <span
                          v-for="(
                            zerConsultScopeItem, h
                          ) in item.zerConsultScopeList"
                          :key="h"
                          >{{ zerConsultScopeItem.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-right">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">
                      线下指导
                      <span v-if="item.location">（{{ item.location }}）</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList && item.zerGuideInfoList.length > 0
                    "
                    class="details"
                  >
                    <div
                      v-for="(zerGuideInfoItem, l) in item.zerGuideInfoList"
                      :key="l"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">{{ zerGuideInfoItem.name }}</div>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList === null ||
                      item.zerGuideInfoList.length == 0
                    "
                    class="details"
                  >
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">暂无</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="i == 0 && isCurrentTeacherInfo && currentPage === 1"
              class="major-banner"
            >
              <img
                src="../assets/images/major_banner.png"
                class="fit-img-cover"
                alt=""
              />
            </div>
          </div>
          <div
            v-if="dataShow.length > 0"
            class="major-pagination sflex sflex-jc"
          >
            <div class="block">
              <el-pagination
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="teacherListTotal"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Major',
  data() {
    return {
      areaList: [],
      schoolList: [],
      teacherList: [],
      subjectTypeStr: '',
      subjectTypeNature: '',
      teacherListTotal: 0,
      schoolDetails: {},
      isSchoolDetails: false,
      currentSchoolName: '',
      searchValue: '',
      totalPageData: [], // 数组内套数组，每个数组就是一页数据
      currentPage: 1, // 当前显示页码
      pageSize: 10, // 每页显示的数量
      pageNum: 1, // 总页数
      total: 0, // 总数量
      dataShow: [], // 当前要显示的数据
      teacherListNew: [], // 当前要显示的数据
      teacherLoopList: [],
      isCurrentTeacherInfo: false,
      allSchoolList: [], // 所有学校列表（用于搜索下拉框）
      schooltitleHeight: 0,
      isSpecialty: false, // 开设专业
      isAcademy: false, // 学院设置
      isScore: false, // 历年高考数据
      tableData: [
        {
          id: '12987122',
          name: '王小虎',
          amount1: '234',
          amount2: '3.2',
          amount3: 10,
        },
        {
          id: '12987123',
          name: '王小虎',
          amount1: '165',
          amount2: '4.43',
          amount3: 12,
        },
        {
          id: '12987124',
          name: '王小虎',
          amount1: '324',
          amount2: '1.9',
          amount3: 9,
        },
        {
          id: '12987125',
          name: '王小虎',
          amount1: '621',
          amount2: '2.2',
          amount3: 17,
        },
        {
          id: '12987126',
          name: '王小虎',
          amount1: '539',
          amount2: '4.1',
          amount3: 15,
        },
      ],
    }
  },
  mounted() {
    this.subjectType = this.$route.query.subjectType
    this.collegeId = this.$route.query.collegeId // 学校id
    this.teacherId = this.$route.query.teacherId
    this.address = this.$route.query.address
    this.subjectTypeStr = this.subjectType === '1' ? '高职' : '高校'
    this.subjectTypeNature = this.subjectType === '1' ? '专科' : '本科'
    // 获取省市学校列表
    this.getAreaList()
    // this.getSchoolList(this.collegeId, this.teacherName)
    // this.getTeacherListBySchoolName(this.teacherName, this.collegeId)

    if (this.teacherId !== '') {
      this.getTeacherInfo(this.teacherId)
    }

    // 获取所有学校列表（用于搜索下拉框）
    this.getAllSchoolList()
  },
  methods: {
    // 切换学校信息
    toggerSchoolDetails(type) {
      if (type === 0) {
        this.isSpecialty = !this.isSpecialty
        this.isAcademy = false
        this.isScore = false
      } else if (type === 1) {
        this.isSpecialty = false
        this.isAcademy = !this.isAcademy
        this.isScore = false
      } else if (type === 2) {
        this.isSpecialty = false
        this.isAcademy = false
        this.isScore = !this.isScore
      }
    },
    // 搜索
    getSearch() {
      if (this.searchValue === '') {
        this.getTeacherListBySchoolName(this.currentSchoolName)
      } else {
        console.log(this.searchValue)
        let currentAddress = ''
        let currentCollegeId = ''
        // this.getTeacherListBySchoolName('')
        this.allSchoolList.forEach((item) => {
          if (this.searchValue === item.name) {
            currentAddress = item.address.split(',')[0]
            currentCollegeId = item.id
          }
        })
        this.areaList.forEach((item, index) => {
          item.isCheck = false
          item.childList = []
          if (item.areaName.indexOf(currentAddress) !== -1) {
            item.isCheck = true
            this.getSchool(item, index, currentCollegeId)
          }
        })
        this.$forceUpdate()
      }
    },

    // 获取全部省市
    getAreaList() {
      let obj = {
        parentId: 0,
      }
      this.$apis.getAreaList(obj).then((response) => {
        if (response.data.code === 200) {
          this.areaList = response.data.data
          this.areaList.forEach((item) => {
            item.childList = []
            if (item.areaName.indexOf(this.address) === 0) {
              item.isCheck = true
            }
            // this.getSchoolList(this.collegeId, this.teacherName, item.areaName)
          })
          if (this.address === '') {
            this.getSchoolList(
              this.collegeId,
              this.teacherName,
              this.areaList[0].areaName
            )
          } else {
            this.getSchoolList(this.collegeId, this.teacherName, this.address)
          }
        }
      })
    },

    addressTostr(str) {
      let addressStr = ''
      if (str.indexOf('省') !== -1 || str.indexOf('市') !== -1) {
        addressStr = str.substring(0, str.length - 1)
      }
      if (str.indexOf('内蒙古自治区') !== -1) {
        addressStr = str.replace(/自治区/, '')
      }
      if (str.indexOf('壮族自治区') !== -1) {
        addressStr = str.replace(/壮族自治区/, '')
      }
      if (str.indexOf('回族自治区') !== -1) {
        addressStr = str.replace(/回族自治区/, '')
      }
      if (str.indexOf('西藏自治区') !== -1) {
        addressStr = str.replace(/自治区/, '')
      }
      if (str.indexOf('维吾尔自治区') !== -1) {
        addressStr = str.replace(/维吾尔自治区/, '')
      }
      if (str.indexOf('特别行政区') !== -1) {
        addressStr = str.replace(/特别行政区/, '')
      }
      return addressStr
    },

    // 获取当前选中省的学校信息列
    getSchool(obj, idx, collegeId = null) {
      let str = obj.areaName
      let addressStr = this.addressTostr(str)
      if (addressStr !== '') {
        console.log(addressStr)
        this.currentSchoolName = ''
        this.isSchoolDetails = false
        this.teacherList = []
        this.teacherListNew = []
        this.dataShow = []
        this.getSchoolList(collegeId, null, addressStr, idx)
      }
    },

    // 获取所有学校列表（用于搜索下拉框）
    getAllSchoolList() {
      let obj = {
        name: '',
        // name: '',
        nature: this.subjectTypeNature,
        onlyHasTeacher: true,
        address: '',
      }
      this.$apis.getSchoolList(obj).then((response) => {
        if (response.data.code === 200) {
          this.allSchoolList = response.data.rows
          console.log(this.allSchoolList)
        }
      })
    },

    // 获取学校列表
    getSchoolList(collegeId, teacherName, address, index) {
      console.log(collegeId, index)
      let obj = {
        name: this.currentSchoolName,
        // name: '',
        nature: this.subjectTypeNature,
        onlyHasTeacher: true,
        address: address,
      }
      this.$apis.getSchoolList(obj).then((response) => {
        if (response.data.code === 200) {
          this.schoolList = response.data.rows
          for (let i = 0; i < this.schoolList.length; i++) {
            this.schoolList[i].isCheck = false
          }
          if (address === '') {
            this.getTeacherListBySchoolName(this.schoolList[0].name, '')
          } else if (address !== '' && index === undefined) {
            let areaIndex = 0
            this.areaList.forEach((item, i) => {
              item.isCheck = false
              item.childList = []
              if (item.areaName.indexOf(address) !== -1) {
                areaIndex = i
              }
            })
            this.areaList[areaIndex].isCheck = true
            this.areaList[areaIndex].childList = this.schoolList
            this.schoolList.forEach((item) => {
              if (collegeId === item.id) {
                this.getSchoolDetails(item)
              }
            })
          }
          if (index != null && index !== undefined) {
            this.areaList.forEach((item) => {
              item.isCheck = false
              item.childList = []
            })
            this.areaList[index].isCheck = true
            this.areaList[index].childList = this.schoolList
            if (this.schoolList.length > 0) {
              if (collegeId === null) {
                this.getSchoolDetails(this.areaList[index].childList[0])
              } else {
                this.schoolList.forEach((item, schoolIdx) => {
                  if (collegeId === item.id) {
                    this.getSchoolDetails(
                      this.areaList[index].childList[schoolIdx]
                    )
                  }
                })
              }
            } else {
              this.isSchoolDetails = false
            }
          }
          if (response.data.rows.length === 0) {
            this.dataShow = []
            this.teacherLoopList = []
          }
          this.$forceUpdate()
        }
      })
    },

    // 获取当前学校信息及教师列表
    getSchoolDetails(obj) {
      this.schoolList.forEach((item, i) => {
        item.isCheck = false
        if (item.id === obj.id) {
          item.isCheck = true
        }
      })
      this.isSchoolDetails = true
      let levelArr = []
      let natureNew = ''
      if (obj.nature != null && obj.nature !== '') {
        natureNew = obj.nature.substring(0, 2)
        levelArr.push(natureNew)
      }
      if (obj.level !== null && obj.level !== '') {
        if (obj.level.indexOf(',') !== -1) {
          obj.levelArr = obj.level.split(',')
          obj.levelArr.splice(0, 0, natureNew)
        } else {
          let arr = []
          arr[0] = obj.level
          obj.levelArr = arr
        }
      } else {
        obj.levelArr = levelArr
      }
      this.schoolDetails = obj
      this.currentSchoolName = obj.name
      this.searchValue = obj.name
      this.getTeacherListBySchoolName(obj.name, obj.id)
    },

    // 通过教师id获取列表
    getTeacherInfo(teacherId) {
      let obj = {
        id: teacherId,
      }
      this.$apis.getTeacherInfo(obj).then((response) => {
        if (response.data.code === 200) {
          let item = response.data.data
          if (item.exp && item.exp !== null) {
            if (item.exp.indexOf('|') !== -1) {
              item.exp = item.exp.split('|')
            } else {
              let arr = []
              arr[0] = item.exp
              item.exp = arr
            }
          }
          if (item.consultMode && item.consultMode !== null) {
            if (item.consultMode.indexOf(',') !== -1) {
              item.consultMode = item.consultMode.split(',')
            } else {
              let arr = []
              arr[0] = item.consultMode
              item.consultMode = arr
            }
          }
          if (item.zerGuideInfoList && item.zerGuideInfoList.length > 0) {
            item.location =
              item.zerGuideInfoList[0].provinceName +
              item.zerGuideInfoList[0].cityName
          } else {
            item.location = ''
          }
          // 获取教师标签
          item.tagArr = this.commit.getTeacherTags(item)
          // 获取教师学校
          item.schoolArr = this.commit.getTeacherSchool(item)
          this.currentTeacherInfo = item
        }
      })
    },

    // 获取学校找老师 （通过查询学校名称）
    async getTeacherListBySchoolName(schoolName, schoolId) {
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        schoolIds: schoolId, // 类型（1-专科、 2-本科）
        // collegeId: schoolName, // 类型（1-专科、 2-本科）
        // realName: teacherName, // 教师姓名
        // searchValue: this.searchValue,
        status: '1',
      }
      this.$apis.getTeacherListBySchoolName(obj).then((response) => {
        if (response.data.code === 200) {
          let teacherArr = response.data.rows
          teacherArr.forEach((item) => {
            if (item.exp && item.exp !== null) {
              if (item.exp.indexOf('|') !== -1) {
                item.exp = item.exp.split('|')
              } else {
                let arr = []
                arr[0] = item.exp
                item.exp = arr
              }
            }
            if (item.consultMode && item.consultMode !== null) {
              if (item.consultMode.indexOf(',') !== -1) {
                item.consultMode = item.consultMode.split(',')
              } else {
                let arr = []
                arr[0] = item.consultMode
                item.consultMode = arr
              }
            }
            if (item.zerGuideInfoList && item.zerGuideInfoList.length > 0) {
              item.location =
                item.zerGuideInfoList[0].provinceName +
                item.zerGuideInfoList[0].cityName
            } else {
              item.location = ''
            }
            // 获取教师标签
            item.tagArr = this.commit.getTeacherTags(item)
            // 获取教师学校
            item.schoolArr = this.commit.getTeacherSchool(item)
          })
          this.teacherListNew = []
          if (schoolId === this.collegeId) {
            if (this.currentTeacherInfo) {
              this.isCurrentTeacherInfo = true
              if (teacherArr.length > 0) {
                this.teacherListNew = [this.currentTeacherInfo, ...teacherArr]
                this.teacherListNew = this.unique(this.teacherListNew)
              } else {
                this.teacherListNew = [this.currentTeacherInfo]
              }
            } else {
              this.teacherListNew = teacherArr
            }
          } else {
            this.teacherListNew = teacherArr
            this.isCurrentTeacherInfo = false
            this.teacherList = this.teacherListNew
          }
          this.teacherListTotal = response.data.total
          this.teacherLoopList = JSON.parse(JSON.stringify(this.teacherListNew))

          this.$nextTick(() => {
            this.initSwiper()
          })
          // 计算页数
          this.calcPageData()

          this.schooltitleHeight = this.$refs.schooltitle.offsetHeight + `px`

          this.isCurrentTeacherInfo = false
        }
      })
    },

    initSwiper() {
      new Swiper('.teacher-swiper', {
        slidesPerView: 6,
        spaceBetween: 40,
        initialSlide: 1,
        observer: true,
        observeParents: false,
        // grid: {
        //   rows: 2,
        //   fill: 'row'
        // },
        // spaceBetween: 30,
        // 如果需要前进后退按钮
        nextButton: '.teacher-next',
        prevButton: '.teacher-prev',
        onSlideChangeEnd: function (swiper) {
          swiper.update()
        },
        breakpoints: {
          320: {
            // 当屏幕宽度大于等于320
            slidesPerView: 1,
            // spaceBetween: 10
          },
          768: {
            // 当屏幕宽度大于等于768
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1280: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1550: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1600: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1700: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1800: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 35,
          },
          2500: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 40,
          },
        },
      })
      this.$nextTick(() => {
        this.$refs.teacherSwiper.swiper.slideTo(0, 300, false)
      })
    },

    // 选中教师轮播模块对应数组移至首位
    getThisTeacher(id, obj) {
      for (var i = 0; i < this.teacherListNew.length; i++) {
        if (this.teacherListNew[i].id === id) {
          this.teacherListNew.splice(i, 1) // 如果数据组存在该元素，则把该元素删除
          break
        }
      }
      this.teacherListNew.unshift(obj) // 再添加到第一个位置
      this.isCurrentTeacherInfo = true
      this.calcPageData()
    },

    // 计算页数
    calcPageData() {
      if (this.teacherListNew.length > 1) {
        this.pageNum =
          Math.ceil(this.teacherListNew.length / this.pageSize) || 1
      }
      for (let i = 0; i < this.pageNum; i++) {
        // 每一页都是一个数组,根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为10， 则第一页是1-10条，即slice(0,10)
        this.totalPageData[i] = this.teacherListNew.slice(
          this.pageSize * i,
          this.pageSize * (i + 1)
        )
      }
      // 获取到数据后显示第一页内容,数组下标是从0开始,所以要减1
      this.dataShow = this.totalPageData[this.currentPage - 1]
      this.$forceUpdate()
    },

    // 每页显示的条数改变时候触发
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize
      this.calcPageData()
    },

    // 当前页改变时候触发
    handleCurrentChange(newPageSize) {
      this.currentPage = newPageSize
      this.dataShow = this.totalPageData[newPageSize - 1]
    },

    // 跳转到首页
    goToIndex() {
      this.$router.push({
        path: '/',
      })
    },
    // 跳转到选专业
    goToMajor() {
      this.$router.push({
        path: '/Major',
      })
    },

    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
  },
}
</script>
<style lang="less">
.choose-major-modal {
  font-size: 0.75rem;
  .specialty-namelist {
    padding: 0 1.5rem;
  }
  .specialty-table-title {
    background: #e0ecfd !important;
    font-size: 0.9rem !important;
    color: #4667c3 !important;
    font-weight: normal;
  }
  .specialty-table {
    .specialty-table-title.is-leaf {
      border: 1px solid #b2cbf8;
      border-right: 1px solid #b2cbf8 !important;
    }
  }
  .line-table {
    .el-table__header-wrapper {
      border: 1px solid #b2cbf8;
      overflow: hidden;
      border-radius: 0.25rem;
    }
  }
  .el-table {
    border-radius: 0.25rem;
    border-top: 1px solid #dedede;
    border-left: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
  .score-pagination {
    margin: 1rem 0 1.75rem;
    .btn-prev,
    .btn-next {
      width: 1.85rem;
      height: 1.85rem;
      border-radius: 50%;
      text-align: center;
      font-size: 0.8rem;
      margin: 0 0.25rem;
      color: #333333;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
    }
    .btn-prev {
      .el-icon {
        margin-left: 0.2rem;
      }
    }
    .btn-next {
      .el-icon {
        margin-right: 0.2rem;
      }
    }
    .el-pager {
      .number {
        width: 1.85rem;
        height: 1.85rem;
        line-height: 1.85rem;
        border-radius: 50%;
        text-align: center;
        font-size: 0.8rem;
        margin: 0 0.25rem;
        color: #333333;
        box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
        font-weight: normal;
      }
    }
    .el-pager li.active {
      background: #7ab5f7;
      color: #fff;
      font-weight: normal;
    }
    .el-pagination button:disabled {
      color: #c0c4cc;
    }
  }
}
</style>

<style lang="less" scoped>
/*下拉框最后一个显示不完全*/
/deep/.el-select-dropdown__wrap.el-scrollbar__wrap {
  margin-bottom: 0 !important;
  text-align: left !important;
}
/deep/.el-input {
  height: 2.05rem;
}
.choose-major-modal {
  background: #f6f7f9;
  .text-blue {
    color: #4861f5;
  }
  .text-bold {
    font-weight: bold;
  }
  .choose-major-head {
    width: 100%;
    height: 5rem;
    background: url(../assets/images/choose_major_head.png) no-repeat;
    background-size: 100% 100%;
    .choose-major-inp {
      width: 33.5rem;
      height: 2.05rem;
      border-radius: 0rem;
      background: #ffffff;
      border-radius: 0.35rem;
      padding: 0 0.9rem;
      font-size: 0.75rem;
      font-family: Microsoft YaHei;
      color: #555555;
      margin-right: 0.4rem;
    }
    .search-btn {
      width: 6.95rem;
      height: 2.05rem;
      line-height: 2.05rem;
      background: linear-gradient(0deg, #00bcd4, #82f1ff);
      border-radius: 0.35rem;
      font-size: 0.9rem;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0.35);
      cursor: pointer;
    }
  }
  .choose-major-main {
    margin: 0 14%;
    .main-bread {
      font-size: 0.75rem;
      font-weight: 400;
      color: #787878;
      padding: 1rem 0 1.1rem;
      img {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.35rem;
      }
      .active {
        color: #333333;
      }
    }
    .main {
      .main-left {
        width: 13rem;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        overflow: hidden;
        .left-title {
          background: url(../assets/images/choose_major_title.png) no-repeat;
          background-size: 100% 100%;
          height: 4.1rem;
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          padding: 0 1rem;
        }
        .left-content {
          text-align: left;
          background: #ffffff;
          padding: 0.75rem;
          max-height: 80vh;
          overflow: hidden;
          overflow-y: auto;
          .left-item {
            padding-bottom: 0.55rem;
            .item-title {
              height: 1.55rem;
              line-height: 1.55rem;
              border-radius: 0.25rem;
              font-size: 0.75rem;
              font-weight: bold;
              color: #333333;
              background: #f6f6f6;
              padding: 0 0.55rem;
              // margin-bottom: 0.5rem;
              cursor: pointer;
            }
            .item-title.active {
              background: #465ff7;
              color: #ffffff;
            }
            .item-title:active .active {
              background: #465ff7;
              color: #ffffff;
            }
            .item-content-box {
              padding-top: 0.55rem;
            }
            .item-content {
              font-size: 0.75rem;
              font-weight: 400;
              color: #787878;
              padding: 0 0.55rem 0.2rem;
              cursor: pointer;
            }
            .item-content.active {
              color: #4760f6;
            }
            .item-content:hover {
              .active {
                color: #4760f6;
              }
            }
            .item-content:last-child {
              padding: 0 0.55rem;
            }
          }
        }

        .left-content::-webkit-scrollbar {
          width: 0px;
        }
      }
      .main-right {
        width: calc(100% - 13rem - 1rem);
        text-align: left;
        .school-img {
          width: 4.8rem;
          height: 4.8rem;
          margin-right: 1.2rem;
        }
        .major-school-title {
          position: relative;
          font-size: 0.7rem;
          width: 100%;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #787878;
          background: #fff;
          border-radius: 0.35rem;
          padding: 1.2rem 2rem 1rem 0.8rem;
          margin-bottom: 0.55rem;
          .name {
            font-size: 0.9rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 1.5rem;
          }
          .content-location {
            margin-bottom: 0.1rem;
          }
          .location-icon {
            width: 0.6rem;
            height: 0.7rem;
            margin: 0 0.35rem 0 0.7rem;
          }
          .slogan {
            font-size: 0.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0.3rem 0 0.7rem;
          }
          .school-rank {
            .rank-item {
              // width: 3.3rem;
              text-align: center;
              padding: 0 0.5rem;
              height: 1.45rem;
              line-height: 1.45rem;
              border-radius: 0.25rem;
              margin-right: 0.55rem;
              margin-bottom: 0.5rem;
            }
            .rank-item:nth-child(5n + 1) {
              background: #fff5e5;
              color: #fb8001;
            }
            .rank-item:nth-child(5n + 2) {
              background: #eaebfa;
              color: #4861f5;
            }
            .rank-item:nth-child(5n + 3) {
              background: #fef1eb;
              color: #ee5681;
            }
            .rank-item:nth-child(5n + 4) {
              background: #e4f3f6;
              color: #2bbec8;
            }
            .rank-item:nth-child(5n + 5) {
              background: #f2e3f6;
              color: #9c50b7;
            }
          }
          .school-details {
            margin-left: 0.5rem;
            margin-top: 0.5rem;
            .details-item {
              // font-size: 0.75rem;
              color: #555555;
              // margin-right: 1.4rem;
              .icon-box {
                width: 2.75rem;
                height: 2.75rem;
                border-radius: 50%;
                background: #e9ecfe;
                margin-bottom: 0.45rem;
              }
              .icon-no-active {
                display: block;
              }
              .icon-active {
                display: none;
              }
              img {
                width: 1.3rem;
                height: 1.3rem;
                display: block;
              }
            }
            .details-item.active {
              color: #4861f5;
              .icon-box {
                background: #4861f5;
              }
              .icon-no-active {
                display: none;
              }
              .icon-active {
                display: block;
              }
            }
          }
        }
        .set-up-specialty-model {
          position: absolute;
          z-index: 99;
          width: 100%;
          left: 0;
          // top: 8rem;
          border: 1px solid #8bbaf1;
          background: #ffffff;
          box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          padding: 1.35rem 1rem 1.85rem;
          .specialty-head {
            position: relative;
            height: 100%;
            border: 1px solid #ededed;
            border-radius: 0.25rem;
            .head-left {
              position: absolute;
              height: 100%;
              width: 7.5rem;
              top: 0;
              left: 0;
              border: 1px solid #b2cbf8;
              border-radius: 0rem;
              background: #e0ecfd;
              border-radius: 0.25rem;
              font-size: 0.9rem;
              color: #4667c3;
              padding: 0 1rem;
            }
            .head-right {
              width: calc(100% - 7.5rem);
              margin-left: 7.5rem;
              padding: 1.1rem 0.65rem 1.1rem 1rem;
              line-height: 1.8rem;
            }
          }
          .specialty-search {
            margin: 1.05rem 0;
            .inp-box {
              height: 2rem;
              border: 1px solid #ededed;
              border-radius: 0.25rem;
              margin-right: 0.5rem;
              padding: 0 0.6rem;
              .inp-icon {
                width: 0.9rem;
                height: 0.9rem;
                margin-right: 0.5rem;
              }
              .search-inp {
                width: 10rem;
                font-size: 0.75rem;
              }
            }
            .search-btn {
              width: 5.2rem;
              height: 2rem;
              line-height: 2rem;
              text-align: center;
              background: #2e7ad2;
              border-radius: 0.25rem;
              font-size: 0.8rem;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
        .set-up-specialty-model::before {
          box-sizing: content-box;
          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.25rem;
          right: 21.8%;
          padding: 0;
          border-bottom: 13px solid #ffffff;
          border-top: 13px solid transparent;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          display: block;
          content: '';
          z-index: 12;
        }
        .set-up-specialty-model::after {
          box-sizing: content-box;
          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.35rem;
          right: calc(21.8% - 1px);
          padding: 0;
          border-bottom: 14px solid #8bbaf1;
          border-top: 14px solid transparent;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          display: block;
          content: '';
          z-index: 10;
        }
        .academy-model {
          position: absolute;
          z-index: 99;
          width: 100%;
          left: 0;
          // top: 8rem;
          border: 1px solid #8bbaf1;
          background: #ffffff;
          box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          padding: 1.2rem 1.9rem 1.45rem;
          .academy-list {
            .academy-item {
              width: 33.33%;
              padding: 0.3rem 0 0.3rem 0.5rem;
              .dot {
                width: 3px;
                height: 3px;
                background: #4c61fc;
                border-radius: 50%;
                margin-right: 0.4rem;
              }
            }
          }
        }
        .academy-model::before {
          box-sizing: content-box;
          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.25rem;
          right: 15.8%;
          padding: 0;
          border-bottom: 13px solid #ffffff;
          border-top: 13px solid transparent;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          display: block;
          content: '';
          z-index: 12;
        }
        .academy-model::after {
          box-sizing: content-box;
          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.35rem;
          right: calc(15.8% - 1px);
          padding: 0;
          border-bottom: 14px solid #8bbaf1;
          border-top: 14px solid transparent;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          display: block;
          content: '';
          z-index: 10;
        }
        .score-model {
          position: absolute;
          z-index: 99;
          width: 100%;
          left: 0;
          // top: 8rem;
          border: 1px solid #8bbaf1;
          background: #ffffff;
          box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          padding: 1.25rem 0.8rem 0;
          .line-header {
            margin-bottom: 1.05rem;
          }
          .line-title {
            font-size: 1rem;
            min-width: 6rem;
            color: #333333;
            .line {
              width: 0.35rem;
              height: 1.05rem;
              border-radius: 0.15rem;
              background: #4861f5;
              margin-right: 0.65rem;
            }
          }
          .line-select {
            .el-dropdown {
              border: 1px solid #ededed;
              padding: 0.4rem 0.6rem;
              margin-left: 0.45rem;
              .el-icon-arrow-down {
                margin-left: 1rem;
              }
            }
          }
          .plan-tips {
            font-size: 0.75rem;
            color: #555555;
            margin-top: 0.95rem;
            span {
              color: #fd0a02;
            }
            margin-bottom: 1.05rem;
          }
          .fraction-line {
            .line-more {
              font-size: 0.7rem;
              color: #4861f5;
              margin: 1.15rem 0 1.55rem;
            }
          }
        }
        .score-model::before {
          box-sizing: content-box;

          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.25rem;
          right: 3.4rem;
          padding: 0;
          border-bottom: 13px solid #ffffff;
          border-top: 13px solid transparent;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          display: block;
          content: '';
          z-index: 12;
        }
        .score-model::after {
          box-sizing: content-box;
          width: 0px;
          height: 0px;
          position: absolute;
          top: -1.35rem;
          right: 3.35rem;
          padding: 0;
          border-bottom: 14px solid #8bbaf1;
          border-top: 14px solid transparent;
          border-left: 14px solid transparent;
          border-right: 14px solid transparent;
          display: block;
          content: '';
          z-index: 10;
        }
        .close {
          z-index: 999;
          position: absolute;
          cursor: pointer;
          content: '';
          width: 1.7rem;
          height: 1.7rem;
          top: -1.7rem;
          right: -2rem;
          background: url(../assets/images/major_close.png) no-repeat;
        }
        .right-content {
          box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.02);
          border-radius: 0.35rem;
          background: #fff;
          margin-bottom: 0.5rem;
          .main-title {
            padding: 1.25rem 0;
            margin: 0 1rem;
            border-bottom: 1px solid #f2f3f4;
            .taecher-content {
              .teac-classify {
                margin-left: 1.6rem;
              }
            }
            .main-btn {
              width: 6.6rem;
              min-width: 6.6rem;
              height: 2.05rem;
              line-height: 2.05rem;
              text-align: center;
              background: linear-gradient(90deg, #425df6, #6a81f8);
              box-shadow: 0rem 0rem 1rem 0rem rgb(71 98 248 / 46%);
              border-radius: 0.25rem;
              // padding: 0 1.75rem;
              font-size: 0.8rem;
              color: #f6f7f9;
              margin-left: 1rem;
            }
          }
          .main-content {
            padding: 0.55rem 0.7rem;
            .content-left {
              position: relative;
              width: 34.8%;
              padding-right: 1rem;
            }
            .content-left::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-center {
              position: relative;
              width: 37.8%;
              padding-left: 0.8rem;
              padding-right: 1rem;
            }
            .content-center::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-right {
              width: 27.4%;
              padding-left: 0.8rem;
            }
            .title {
              font-size: 0.8rem;
              font-weight: bold;
              color: #333333;
              .title-img {
                width: 1.65rem;
                height: 1.65rem;
                border-radius: 50%;
                margin-right: 0.45rem;
              }
            }
            .details {
              padding: 0.6rem 0;
              .details-item {
                font-size: 0.75rem;
                color: #555555;
                line-height: 1.3rem;
                margin: 0 0.6rem;
                .dot {
                  max-width: 0.35rem;
                  max-height: 0.35rem;
                  min-width: 0.35rem;
                  min-height: 0.35rem;
                  width: 0.35rem;
                  height: 0.35rem;
                  border-radius: 50%;
                  background: #63a1fd;
                  margin-top: 0.4rem;
                  margin-right: 0.45rem;
                }
                .text-blue {
                  color: #4861f5;
                }
                .text-bold {
                  font-weight: bold;
                }
                .text-tips {
                  font-size: 0.65rem;
                }
              }
            }
          }
        }
        .major-banner {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .choose-major-modal {
    .choose-major-main {
      .main-right {
        .set-up-specialty-model::before {
          top: -1.25rem;
          right: 25% !important;
        }
        .set-up-specialty-model::after {
          top: -1.35rem;
          right: calc(25% - 1px) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 8%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
      .main-right {
        .right-content {
          .main-title {
            .taecher-content {
              flex-wrap: wrap;
              .teac-synopsis {
                width: calc(100% - 5.25rem);
              }
              .teac-classify {
                width: 100%;
                flex-grow: 1;
                margin-left: 0 !important;
                margin-top: 0.5rem !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .choose-major-modal {
    .choose-major-main {
      .major-school-title {
        flex-direction: column;
        padding: 1.2rem !important;
        .school-img {
          min-width: 4.8rem;
          min-height: 4.8rem;
        }
        .school-introduce {
          .school-rank {
            flex-wrap: wrap;
            .rank-item {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .choose-major-modal {
    .choose-major-head {
      padding: 0 4%;
    }
    .choose-major-main {
      margin: 0 1rem !important;
      .main-left {
        width: 10rem !important;
      }
      .main-right {
        width: calc(100% - 10rem - 1rem) !important;
        .major-school-title {
          flex-direction: column;
          padding: 1.2rem !important;
          .school-img {
            min-width: 4.8rem;
            min-height: 4.8rem;
          }
          .school-introduce {
            flex-wrap: wrap;
            .school-introduce-name {
              flex-direction: column;
              align-items: flex-start;
              .location-icon {
                margin: 0 0.35rem 0 0 !important;
              }
            }
            .school-rank {
              flex-wrap: wrap;
              .rank-item {
                margin-bottom: 0.5rem;
              }
            }
          }
          .school-details {
            margin-left: 0 !important;
          }
        }
        .set-up-specialty-model::before {
          top: -1.25rem;
          left: 32% !important;
        }
        .set-up-specialty-model::after {
          top: -1.35rem;
          left: calc(32% - 1px) !important;
        }
        .academy-model::before {
          top: -1.25rem;
          right: 40% !important;
        }
        .academy-model::after {
          top: -1.35rem;
          right: calc(40% - 1px) !important;
        }
        .score-model {
          .line-select {
            .el-dropdown {
              margin-bottom: 0.5rem;
            }
          }
        }
        .score-model::before {
          top: -1.25rem;
          right: 14% !important;
        }
        .score-model::after {
          top: -1.35rem;
          right: calc(14% - 1px) !important;
        }
        .close {
          width: 1.7rem;
          height: 1.7rem;
          top: -1.7rem !important;
          right: -1rem !important;
        }
        .right-content {
          .main-title {
            flex-direction: column;
          }
          .main-content {
            flex-direction: column;
            .content-left {
              width: 100% !important;
              padding-right: 0 !important;
            }
            .content-left::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-center {
              width: 100% !important;
              margin-top: 0.5rem;
            }
            .content-center::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-right {
              width: 100% !important;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
