<template>
  <div class="choose-major-modal">
    <!-- <div class="choose-major-head sflex sflex-ac sflex-jc">
      <input
        v-model="search"
        type="text"
        placeholder="考研学校"
        class="choose-major-inp"
      />
      <input
        v-model="search"
        type="text"
        placeholder="考研学科"
        class="choose-major-inp"
      />
      <input
        v-model="search"
        type="text"
        placeholder="咨询方式"
        class="choose-major-inp"
      />
      <div class="search-btn" @click="getSearch()">查询</div>
    </div> -->
    <div class="choose-major-main">
      <div class="main-bread sflex sflex-ac">
        <img src="../assets/images/choose_major_home.png" alt="" />
        <div>
          <span class="hand" @click="goToIndex">首页</span> >
          <span class="hand" @click="goToMajor">提分数</span>
          <span v-if="searchNav != ''">
            <span>>&nbsp;</span>
            <span class="active hand">{{ searchNav }}学习经验分享</span>
          </span>
        </div>
      </div>
      <div class="main sflex sflex-jsp">
        <div class="main-left">
          <div class="left-title sflex sflex-ac sflex-jc">提分数</div>
          <div class="left-content">
            <div v-for="(item, a) in navList" :key="a" class="left-item">
              <div
                class="item-title ut-s"
                :class="{ active: item.isCheck }"
                @click="navSearch(item.dictValue)"
              >
                {{ item.dictValue }}学习经验分享
              </div>
              <!-- <div v-if="item.childList.length > 0" class="item-content-box">
                <div
                  v-for="(childItem, b) in item.childList"
                  :key="b"
                  class="item-content ut-s"
                  :class="{ active: childItem.isCheck }"
                  @click="
                    getThird(
                      childItem.id,
                      childItem.parentId,
                      childItem.categoryName
                    )
                  "
                >
                  {{ childItem.code }} {{ childItem.categoryName }}
                </div>
              </div> -->
              <!-- <div class="item-content-box">
                <div class="item-content active ut-s">北京大学</div>
                <div class="item-content ut-s">清华大学</div>
                <div class="item-content ut-s">中国人民大学</div>
                <div class="item-content ut-s">北京理工大学</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="main-right">
          <div
            v-if="areaList && areaList.length > 0"
            class="right-title sflex sflex-jsp"
          >
            <div
              class="title-icon hand"
              :class="{ 'title-icon-active': searchArea == '' ? true : false }"
              @click="areaSearch('')"
            >
              全部
            </div>
            <div class="title-content sflex sflex-ac sflex-wrap">
              <div
                v-for="(item, s) in areaList"
                :key="s"
                class="title-content-text sflex sflex-ac sflex-wrap hand"
                :class="{
                  areaActive: searchArea == item.areaName ? true : false,
                }"
                @click="areaSearch(item.areaName)"
              >
                {{ item.areaName }}
                <!-- <div v-if="areaList.length - 1 > s" class="line">
                  &nbsp;/&nbsp;
                </div> -->
              </div>
            </div>
          </div>
          <div
            v-if="teacherLoopList && teacherLoopList.length > 0"
            class="teacher-loop-model"
          >
            <div class="teacher-swiper" ref="teacherSwiper">
              <div class="swiper-wrapper">
                <div
                  v-for="(item, teacherIdx) in teacherLoopList"
                  :key="teacherIdx"
                  class="swiper-slide"
                  @click="getThisTeacher(item.id, item)"
                >
                  <img
                    class="icon-active"
                    :src="
                      item.avatar
                        ? item.avatar
                        : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                    "
                    alt=""
                  />
                  <div>{{ item.realName }}{{ item.teacherTitle }}</div>
                </div>
              </div>
              <div class="swiper-button-prev teacher-prev"></div>
              <div class="swiper-button-next teacher-next"></div>
            </div>
          </div>
          <div v-for="(item, i) in dataShow" :key="i">
            <div class="right-content">
              <div class="main-title sflex sflex-ac sflex-jsp">
                <div class="taecher-content sflex sflex-as sflex-grow">
                  <div class="content-img-box sflex sflex-ac sflex-y">
                    <div class="head-img">
                      <img
                        class="fit-img-cover"
                        :src="
                          item.avatar
                            ? item.avatar
                            : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                        "
                        alt=""
                      />
                    </div>
                    <div class="title-img">
                      <img
                        class="fit-img-cover"
                        src="../assets/images/teacher_title.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="teac-synopsis">
                    <div class="sflex sflex-ac">
                      <div class="teac-name ut-s">
                        {{ item.realName }}{{ item.teacherTitle }}
                      </div>
                      <div
                        v-for="(consultModeItem, n) in item.consultMode"
                        :key="n"
                        class="teac-attr"
                      >
                        {{
                          consultModeItem == '1'
                            ? '图文'
                            : consultModeItem == '2'
                            ? '电话'
                            : '线下'
                        }}
                      </div>
                    </div>
                    <div class="sflex sflex-ac teac-location">
                      <img
                        src="../assets/images/major_location.png"
                        class="location-icon"
                        alt=""
                      />
                      <div class="ut-s2">
                        {{ item.provinceName }}{{ item.cityName }}
                      </div>
                    </div>
                    <div class="teac-txt">
                      {{ item.teacherName }}<br />
                      {{ item.teacherDescribe }}
                    </div>
                  </div>
                  <div class="teac-classify sflex sflex-ac sflex-wrap">
                    <!-- <div class="classify-item">专业咨询</div> -->
                    <div
                      v-for="(tagItem, r) in item.tagArr"
                      :key="r"
                      class="classify-item"
                    >
                      {{ tagItem }}
                    </div>
                  </div>
                </div>
                <div class="main-btn">扫码咨询</div>
              </div>
              <div class="main-content sflex">
                <div class="content-left">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">教育（工作）背景</div>
                  </div>
                  <div class="details">
                    <div
                      v-for="(expItem, j) in item.exp"
                      :key="j"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        {{ expItem }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-center">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">线上咨询</div>
                  </div>
                  <div class="details">
                    <div class="details-item sflex">
                      <!-- <div class="dot"></div> -->
                      <div class="sflex-grow">
                        <template v-if="item.teacherType == 3">
                          <div class="sflex">
                            <div class="dot"></div>
                            <span class="text-blue text-bold">考研初试：</span
                            ><br />
                          </div>
                          <div class="details-item-text">
                            <span
                              v-if="
                                item.serviceLabel1 &&
                                item.serviceLabel1.length === 0
                              "
                              >"暂无"</span
                            >
                            <span
                              v-for="(
                                serviceLabel1Item, serviceLabel1k
                              ) in item.serviceLabel1"
                              :key="serviceLabel1k"
                            >
                              {{ serviceLabel1Item.text }}
                              <span
                                v-if="
                                  item.serviceLabel1.length - 1 > serviceLabel1k
                                "
                                class="text-blue"
                                >&nbsp;/&nbsp;</span
                              >
                            </span>
                          </div>
                          <div class="sflex">
                            <div class="dot"></div>
                            <span class="text-blue text-bold">考研复试：</span
                            ><br />
                          </div>
                          <div class="details-item-text">
                            <span
                              v-if="
                                item.serviceLabel2 &&
                                item.serviceLabel2.length === 0
                              "
                              >"暂无"</span
                            >
                            <span
                              v-for="(
                                serviceLabel2Item, serviceLabel2k
                              ) in item.serviceLabel2"
                              :key="serviceLabel2k"
                            >
                              {{ serviceLabel2Item.text }}
                              <span
                                v-if="
                                  item.serviceLabel2.length - 1 > serviceLabel2k
                                "
                                class="text-blue"
                                >&nbsp;/&nbsp;</span
                              >
                            </span>
                          </div>
                        </template>

                        <template
                          v-if="
                            item.teacherType == 2 ||
                            (item.teacherType == 3 &&
                              item.goodSubjectsArr &&
                              item.goodSubjectsArr.length > 0)
                          "
                        >
                          <div class="sflex">
                            <div class="dot"></div>
                            <span class="text-blue text-bold">中学科目：</span
                            ><br />
                          </div>
                          <div class="details-item-text">
                            <span
                              v-if="
                                item.goodSubjectsArr &&
                                item.goodSubjectsArr.length === 0
                              "
                              >"暂无"</span
                            >
                            <span
                              v-for="(
                                goodSubjectsItem, goodSubjectsk
                              ) in item.goodSubjectsArr"
                              :key="goodSubjectsk"
                            >
                              {{ goodSubjectsItem }}
                              <span
                                v-if="
                                  item.goodSubjectsArr.length - 1 >
                                  goodSubjectsk
                                "
                                class="text-blue"
                                >&nbsp;/&nbsp;</span
                              >
                            </span>
                          </div>
                          <div v-if="item.otherConsult" class="sflex">
                            <div class="dot"></div>
                            <span class="text-blue text-bold">其他：</span
                            ><br />
                          </div>
                          <div
                            v-if="
                              item.otherConsult && item.otherConsult.length > 0
                            "
                            class="details-item-text"
                          >
                            <span
                              v-for="(
                                otherConsultItem, index1
                              ) in item.otherConsult"
                              :key="otherConsultItem.id"
                            >
                              {{ otherConsultItem }}
                              <span
                                v-if="index1 < item.otherConsult.length - 1"
                                class="text-blue"
                                >&nbsp;/&nbsp;</span
                              >
                            </span>
                          </div>
                        </template>

                        <!-- <span class="text-blue text-bold">专业：</span>
                      <br />
                      <div
                        v-for="(specialityItem, k) in item.specialityList"
                        :key="k"
                      >
                        <span class="text-blue">
                          {{ specialityItem.code
                          }}{{ specialityItem.categoryName }}：
                        </span>
                        <span
                          v-for="(
                            specialityChildItem, m
                          ) in specialityItem.childList"
                          :key="m"
                          >{{ specialityChildItem.categoryName }}
                          <span
                            v-if="specialityItem.childList.length - 1 > m"
                            class="text-blue"
                            >/&nbsp;</span
                          >
                        </span>
                      </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-right">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">
                      线下指导
                      <span v-if="item.location">（{{ item.location }}）</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList && item.zerGuideInfoList.length > 0
                    "
                    class="details"
                  >
                    <div
                      v-for="(zerGuideInfoItem, l) in item.zerGuideInfoList"
                      :key="l"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">{{ zerGuideInfoItem.name }}</div>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList === null ||
                      item.zerGuideInfoList.length == 0
                    "
                    class="details"
                  >
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">暂无</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="i == 0 && isCurrentTeacherInfo && currentPage === 1"
              class="major-banner"
            >
              <img
                src="../assets/images/major_banner.png"
                class="fit-img-cover"
                alt=""
              />
            </div>
          </div>
          <div
            v-if="dataShow.length > 0"
            class="major-pagination sflex sflex-jc"
          >
            <div class="block">
              <el-pagination
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="teacherListTotal"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      specialityList: [],
      specialityArr: [],
      teacherList: [],
      subjectTypeStr: '',
      teacherListTotal: 0,
      thirdSpecialityList: [],
      currentCategoryName: '',
      search: '',
      totalPageData: [], // 数组内套数组，每个数组就是一页数据
      currentPage: 1, // 当前显示页码
      pageSize: 10, // 每页显示的数量
      pageNum: 1, // 总页数
      total: 0, // 总数量
      dataShow: [], // 当前要显示的数据
      teacherLoopList: [],

      areaList: [], // 地区列表
      navList: [], // 左边导航列表

      searchNav: '', // 默认选中的左边导航
      searchArea: '', // 地区搜索
      searchRealname: '',
      isCurrentTeacherInfo: false,
    }
  },
  created() {
    this.subjectType = this.$route.query.subjectType

    this.subjectTypeStr = this.subjectType === '1' ? '高职' : '高校'
    // this.getList(this.subjectType) // 加载专业分类列表

    this.getAllAreaList() // 获取省市数据
    this.getNavList() // 获取左边导航的数据
    this.searchNav = this.$route.query.dictValue
    this.searchRealname = this.$route.query.realname
  },
  methods: {
    // 获取省市数据
    getAllAreaList() {
      let obj = {
        parentId: 0,
      }
      this.$apis.getAreaList(obj).then((response) => {
        this.areaList = response.data.data
        this.areaList.forEach((item) => {
          if (
            item.areaName.indexOf('省') !== -1 ||
            item.areaName.indexOf('市') !== -1
          ) {
            item.areaName = item.areaName.substring(0, item.areaName.length - 1)
          }
          if (item.areaName.indexOf('内蒙古自治区') !== -1) {
            item.areaName = item.areaName.replace(/自治区/, '')
          }
          if (item.areaName.indexOf('壮族自治区') !== -1) {
            item.areaName = item.areaName.replace(/壮族自治区/, '')
          }
          if (item.areaName.indexOf('回族自治区') !== -1) {
            item.areaName = item.areaName.replace(/回族自治区/, '')
          }
          if (item.areaName.indexOf('西藏自治区') !== -1) {
            item.areaName = item.areaName.replace(/自治区/, '')
          }
          if (item.areaName.indexOf('维吾尔自治区') !== -1) {
            item.areaName = item.areaName.replace(/维吾尔自治区/, '')
          }
          if (item.areaName.indexOf('特别行政区') !== -1) {
            item.areaName = item.areaName.replace(/特别行政区/, '')
          }
        })
      })
    },
    // 获取左边导航的数据
    getNavList() {
      this.$apis.getDictDatal('good_subjects').then((response) => {
        this.navList = response.data.data
        this.navList.forEach((item, i) => {
          item.isCheck = false
          if (item.dictValue === this.searchNav) {
            item.isCheck = true
            this.searchNav = item.dictValue
            this.getSpecialityListByName()
          }
        })
      })
    },
    // 导航条搜索
    navSearch(dictValue) {
      this.searchNav = dictValue
      this.searchRealname = ''
      this.navList.forEach((item, i) => {
        item.isCheck = false
        if (item.dictValue === dictValue) {
          item.isCheck = true
        }
      })
      this.getSpecialityListByName()
    },
    // 地区搜索
    areaSearch(areaSearch) {
      this.searchRealname = ''
      this.searchArea = areaSearch
      this.getSpecialityListByName()
    },
    getSearch() {},
    getList(subjectType) {
      // 获取专业分类列表
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        subjectType: subjectType, // 类型（1-专科、 2-本科）
      }
      this.$apis.getSpecialityList(obj).then((response) => {
        if (response.data.code === 200) {
          this.specialityList = response.data.rows
          let specialityArr = []
          for (let i = 0; i < this.specialityList.length; i++) {
            this.specialityList[i].isCheck = false
            if (this.specialityList[i].parentId === '0') {
              specialityArr.push(this.specialityList[i])
              this.specialityList[i].childList = []

              for (let j = 0; j < this.specialityList.length; j++) {
                if (
                  this.specialityList[j].parentId === this.specialityList[i].id
                ) {
                  this.specialityList[i].childList.push(this.specialityList[j])
                }
              }
            }
          }
          // 默认选中第一个分类
          specialityArr[0].isCheck = true
          this.getThird(
            specialityArr[0].id,
            specialityArr[0].parentId,
            specialityArr[0].categoryName
          )
          this.specialityArr = specialityArr
          this.currentCategoryName = specialityArr[0].categoryName
        }
      })
    },
    getSpecialityListByName() {
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        goodSubjects: this.searchNav,
        provinceName: this.searchArea,
        collegeProvinceName: '', // 高考所在地
        consultMode: '',
        realName: '',
        status: 1,
      }
      this.$apis.getSpecialityListByName(obj).then((response) => {
        if (response.data.code === 200) {
          this.isCurrentTeacherInfo = false
          let teacherArr = response.data.rows
          teacherArr.forEach((item, index) => {
            if (item.serviceLabel) {
              let consultArr = JSON.parse(item.serviceLabel)
              item.serviceLabel1 = consultArr.filter((p) => p.id === 120)
              item.serviceLabel2 = consultArr.filter((p) => p.id === 121)
            }
            item.goodSubjectsArr = []
            item.goodSubjectsArr = item.goodSubjects.split(',')
            if (item.exp && item.exp !== null) {
              if (item.exp.indexOf('|') !== -1) {
                item.exp = item.exp.split('|')
              } else {
                let arr = []
                arr[0] = item.exp
                item.exp = arr
              }
            }
            if (item.consultMode && item.consultMode !== null) {
              if (item.consultMode.indexOf(',') !== -1) {
                item.consultMode = item.consultMode.split(',')
              } else {
                let arr = []
                arr[0] = item.consultMode
                item.consultMode = arr
              }
            }
            if (item.zerConsultScopeList) {
              if (item.zerConsultScopeList.length > 0) {
                item.otherConsult = item.zerConsultScopeList.map((res) => {
                  return res.name
                })
              }
            }
            if (item.zerGuideInfoList && item.zerGuideInfoList.length > 0) {
              item.location =
                item.zerGuideInfoList[0].provinceName +
                item.zerGuideInfoList[0].cityName
            } else {
              item.location = ''
            }
            // 获取教师标签
            item.tagArr = this.commit.getTeacherTags(item)

            if (item.realName === this.searchRealname) {
              this.isCurrentTeacherInfo = true
              let str = teacherArr.splice(index, 1)
              teacherArr.unshift(str[0])
            }
          })
          this.teacherList = teacherArr
          this.teacherListTotal = response.data.total
          this.teacherLoopList = JSON.parse(JSON.stringify(this.teacherList))

          this.$nextTick(() => {
            this.initSwiper()
          })
          // 计算页数
          this.calcPageData()

          this.isCurrentTeacherInfo = false
        }
      })
    },

    initSwiper() {
      new Swiper('.teacher-swiper', {
        slidesPerView: 6,
        spaceBetween: 40,
        initialSlide: 1,
        observer: true,
        observeParents: false,
        // grid: {
        //   rows: 2,
        //   fill: 'row'
        // },
        // spaceBetween: 30,
        // 如果需要前进后退按钮
        nextButton: '.teacher-next',
        prevButton: '.teacher-prev',
        onSlideChangeEnd: function (swiper) {
          swiper.update()
        },
        breakpoints: {
          320: {
            // 当屏幕宽度大于等于320
            slidesPerView: 1,
            // spaceBetween: 10
          },
          768: {
            // 当屏幕宽度大于等于768
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1280: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1550: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1600: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1700: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1800: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 35,
          },
          2500: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 40,
          },
        },
      })
      this.$nextTick(() => {
        this.$refs.teacherSwiper.swiper.slideTo(0, 300, false)
      })
    },

    // 获取第三级分类专业
    getThird(id, parentId, categoryName) {
      this.currentCategoryName = categoryName
      this.search = ''
      for (let i = 0; i < this.specialityList.length; i++) {
        this.specialityList[i].isCheck = false
        if (this.specialityList[i].id === id) {
          this.specialityList[i].isCheck = true
        }
        if (parentId !== '0' && this.specialityList[i].id === parentId) {
          this.specialityList[i].isCheck = true
        }
      }
      this.getSpecialityListByName(categoryName)

      this.thirdSpecialityList = []
      for (let i = 0; i < this.specialityList.length; i++) {
        if (this.specialityList[i].parentId === id) {
          this.thirdSpecialityList.push(this.specialityList[i])
        }
      }
    },

    // 选中教师轮播模块对应数组移至首位
    getThisTeacher(id, obj) {
      for (var i = 0; i < this.teacherList.length; i++) {
        if (this.teacherList[i].id === id) {
          this.teacherList.splice(i, 1) // 如果数据组存在该元素，则把该元素删除
          break
        }
      }
      this.teacherList.unshift(obj) // 再添加到第一个位置
      this.isCurrentTeacherInfo = true
      this.calcPageData()
    },

    // 计算页数
    calcPageData() {
      if (this.teacherList.length > 1) {
        this.pageNum = Math.ceil(this.teacherList.length / this.pageSize) || 1
      }
      for (let i = 0; i < this.pageNum; i++) {
        // 每一页都是一个数组,根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为10， 则第一页是1-10条，即slice(0,10)
        this.totalPageData[i] = this.teacherList.slice(
          this.pageSize * i,
          this.pageSize * (i + 1)
        )
      }
      // 获取到数据后显示第一页内容,数组下标是从0开始,所以要减1
      this.dataShow = this.totalPageData[this.currentPage - 1]
      this.$forceUpdate()
    },
    // 每页显示的条数改变时候触发
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize
      this.calcPageData()
    },
    // 当前页改变时候触发
    handleCurrentChange(newPageSize) {
      this.currentPage = newPageSize
      this.dataShow = this.totalPageData[newPageSize - 1]
    },

    // 跳转到首页
    goToIndex() {
      this.$router.push({
        path: '/',
      })
    },
    // 跳转到选专业
    goToMajor() {
      this.$router.push({
        path: '/Score',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.choose-major-modal {
  background: #f6f7f9;
  .text-blue {
    color: #4861f5;
  }
  .text-bold {
    font-weight: bold;
  }
  .choose-major-head {
    width: 100%;
    height: 5rem;
    // background: url(../assets/images/choose_major_head.png) no-repeat;
    background-size: 100% 100%;
    padding: 0 20rem;
    .choose-major-inp {
      width: 30%;
      height: 2.05rem;
      border-radius: 0rem;
      background: #ffffff;
      border-radius: 0.35rem;
      padding: 0 0.9rem;
      font-size: 0.75rem;
      font-family: Microsoft YaHei;
      color: #555555;
      margin-right: 0.4rem;
    }
    .choose-major-inp:first-child {
      width: 40%;
    }
    .search-btn {
      width: 6.95rem;
      height: 2.05rem;
      line-height: 2.05rem;
      background: linear-gradient(0deg, #00bcd4, #82f1ff);
      border-radius: 0.35rem;
      font-size: 0.9rem;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0.35);
      cursor: pointer;
    }
  }
  .choose-major-main {
    margin: 0 14%;
    .main-bread {
      font-size: 0.75rem;
      font-weight: 400;
      color: #787878;
      padding: 1rem 0 1.1rem;
      img {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.35rem;
      }
      .active {
        color: #333333;
      }
    }
    .main {
      .main-left {
        // width: 17.5%;
        width: 13rem;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        overflow: hidden;
        .left-title {
          background: url(../assets/images/choose_major_title.png) no-repeat;
          background-size: 100% 100%;
          height: 4.1rem;
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          padding: 0 1rem;
        }
        .left-content {
          text-align: left;
          background: #ffffff;
          padding: 0.75rem;
          max-height: 80vh;
          overflow: hidden;
          overflow-y: auto;
          .left-item {
            padding-bottom: 0.55rem;
            .item-title {
              height: 1.55rem;
              line-height: 1.55rem;
              border-radius: 0.25rem;
              font-size: 0.75rem;
              font-weight: 700;
              color: #333;
              background: #f6f6f6;
              padding: 0 0.55rem;
              cursor: pointer;
            }
            .item-title.active {
              background: #465ff7;
              color: #ffffff;
            }
            .item-content-box {
              padding: 0.55rem 0;
            }
            .item-content {
              font-size: 0.75rem;
              font-weight: 400;
              color: #787878;
              padding: 0 0.55rem 0.2rem;
              cursor: pointer;
            }
            .item-content.active {
              color: #4760f6;
            }
            .item-content:hover {
              .active {
                color: #4760f6;
              }
            }
            .item-content:last-child {
              padding: 0 0.55rem;
            }
          }
        }
      }
      .main-right {
        width: calc(100% - 13rem - 1rem);
        text-align: left;
        .right-title {
          width: 100%;
          // height: 4.7rem;
          border: 1px solid #9cc4fe;
          box-shadow: 0rem 0rem 1rem 0rem rgba(99, 161, 253, 0.35);
          border-radius: 0.35rem;
          margin-bottom: 0.55rem;
          .title-icon {
            width: 2.2rem;
            height: 1.25rem;

            text-align: center;
            border-radius: 0.35rem;
            // background: url(../assets/images/major_title.png);
            background-size: 100% 100%;
            line-height: 1.25rem;
            font-size: 0.75rem;

            margin: 1rem 0.65rem;
          }
          .title-icon-active {
            background: #63a1fd;
            color: #ffffff;
          }
          .title-content {
            color: #666666;
            font-size: 0.75rem;
            width: 100%;
            margin: 1rem 0;
            padding-right: 1rem;
            .title-content-text {
              // padding-right: 1rem;
              // padding-bottom: 0.5rem;
              padding: 0.25rem 0.25rem;
            }
            .areaActive {
              background: #63a1fd;
              line-height: 1.25rem;
              font-size: 0.75rem;
              color: #ffffff;
              border-radius: 0.35rem;
            }
          }
          .line {
            color: #455ef7;
            font-size: 0.9rem;
            line-height: 1.25rem;
          }
        }
        .right-content {
          box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.02);
          border-radius: 0.35rem;
          background: #fff;
          margin: 0.55rem 0 0.5rem;
          .main-title {
            padding: 1.25rem 0;
            margin: 0 1rem;
            border-bottom: 1px solid #f2f3f4;
            .taecher-content {
              .content-img-box {
                margin-bottom: 0.25rem;
                .head-img {
                  width: 4rem;
                  height: 4rem;
                  border-radius: 50%;
                  overflow: hidden;
                }
                .title-img {
                  width: 4.25rem;
                  height: 1.2rem;
                  margin-top: 0;
                  z-index: 2;
                }
              }
              .teac-classify {
                margin-left: 1.6rem;
              }
            }
            .main-btn {
              width: 6.6rem;
              min-width: 6.6rem;
              height: 2.05rem;
              line-height: 2.05rem;
              text-align: center;
              background: linear-gradient(90deg, #425df6, #6a81f8);
              box-shadow: 0rem 0rem 1rem 0rem rgb(71 98 248 / 46%);
              border-radius: 0.25rem;
              // padding: 0 1.75rem;
              font-size: 0.8rem;
              color: #f6f7f9;
              margin-left: 1rem;
            }
          }
          .main-content {
            padding: 0.55rem 0.7rem;
            .content-left {
              position: relative;
              width: 34.8%;
              padding-right: 1rem;
            }
            .content-left::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-center {
              position: relative;
              width: 37.8%;
              padding-left: 0.8rem;
              padding-right: 1rem;
            }
            .content-center::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-right {
              width: 27.4%;
              padding-left: 0.8rem;
            }
            .title {
              font-size: 0.8rem;
              font-weight: bold;
              color: #333333;
              .title-img {
                width: 1.65rem;
                height: 1.65rem;
                border-radius: 50%;
                margin-right: 0.45rem;
              }
            }
            .details {
              padding: 0.6rem 0;
              .details-item {
                font-size: 0.75rem;
                color: #555555;
                line-height: 1.3rem;
                margin: 0 0.6rem;
                .dot {
                  max-width: 0.35rem;
                  max-height: 0.35rem;
                  min-width: 0.35rem;
                  min-height: 0.35rem;
                  width: 0.35rem;
                  height: 0.35rem;
                  border-radius: 50%;
                  background: #63a1fd;
                  margin-top: 0.4rem;
                  margin-right: 0.45rem;
                }
                .details-item-text {
                  padding-left: 0.8rem;
                }
              }
            }
          }
        }
        .major-banner {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 8%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
      .main-right {
        .right-content {
          .main-title {
            .taecher-content {
              flex-wrap: wrap;
              .teac-synopsis {
                width: calc(100% - 5.25rem);
              }
              .teac-classify {
                width: 100%;
                flex-grow: 1;
                margin-left: 0 !important;
                margin-top: 0.5rem !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .choose-major-modal {
    .choose-major-head {
      padding: 0 4%;
    }
    .choose-major-main {
      margin: 0 1rem !important;
      .main-left {
        width: 10rem !important;
      }
      .main-right {
        width: calc(100% - 10rem - 1rem) !important;
        .right-content {
          .main-title {
            flex-direction: column;
          }
          .main-content {
            flex-direction: column;
            .content-left {
              width: 100% !important;
              padding-right: 0 !important;
            }
            .content-left::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-center {
              width: 100% !important;
              margin-top: 0.5rem;
            }
            .content-center::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-right {
              width: 100% !important;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
