<template>
  <div class="choose-major-modal">
    <div class="choose-major-head sflex sflex-ac sflex-jc">
      <!-- <input
        v-model="search"
        type="text"
        placeholder="学科 / 专业"
        class="choose-major-inp"
      /> -->
      <el-select
        v-model="search"
        filterable
        clearable
        placeholder="学科 / 专业"
        class="choose-major-inp"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="(item, allCategroyIdx) in allCategroyArr"
          :key="allCategroyIdx"
          :label="item.categoryName"
          :value="item.categoryName"
        >
        </el-option>
      </el-select>
      <div class="search-btn" @click="getSearch()">查询</div>
    </div>
    <div class="choose-major-main">
      <div class="main-bread sflex sflex-ac">
        <img src="../assets/images/choose_major_home.png" alt="" />
        <div>
          <span class="hand" @click="goToIndex">首页</span> >
          <span class="hand" @click="goToMajor">选专业</span> >
          <span class="hand">按专业找老师（{{ subjectTypeStr }}）</span>
          <span v-if="currentCategoryName != ''">
            <span>>&nbsp;</span>
            <span class="active hand">{{ currentCategoryName }}</span>
          </span>
        </div>
      </div>
      <div class="main sflex sflex-jsp">
        <div class="main-left">
          <div class="left-title sflex sflex-ac sflex-jc">
            按专业找老师（{{ subjectTypeStr }}）
          </div>
          <div class="left-content">
            <div v-for="(item, a) in specialityArr" :key="a" class="left-item">
              <div
                class="item-title ut-s"
                :class="{ active: item.isCheck }"
                @click="getThird(item.id, item.parentId, item.categoryName, 1)"
              >
                {{ item.categoryName }}
              </div>
              <div v-if="item.childListNew.length > 0" class="item-content-box">
                <div
                  v-for="(childItem, b) in item.childListNew"
                  :key="b"
                  class="item-content ut-s"
                  :class="{ active: childItem.isCheck }"
                  @click="
                    getThird(
                      childItem.id,
                      childItem.parentId,
                      childItem.categoryName
                    )
                  "
                >
                  {{ childItem.code }} {{ childItem.categoryName }}
                </div>
              </div>
              <!-- <div class="item-content-box">
                <div class="item-content active ut-s">北京大学</div>
                <div class="item-content ut-s">清华大学</div>
                <div class="item-content ut-s">中国人民大学</div>
                <div class="item-content ut-s">北京理工大学</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="main-right">
          <div
            v-if="thirdSpecialityList.length > 0"
            class="right-title sflex sflex-jsp"
          >
            <div class="title-icon">专业</div>
            <div class="title-content sflex sflex-ac sflex-wrap">
              <div
                v-for="(item, s) in thirdSpecialityList"
                :key="s"
                class="sflex sflex-ac sflex-wrap"
              >
                {{ item.categoryName }}
                <div v-if="thirdSpecialityList.length - 1 > s" class="line">
                  &nbsp;/&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="teacherLoopList && teacherLoopList.length > 0"
            class="teacher-loop-model"
          >
            <div class="teacher-swiper" ref="teacherSwiper">
              <div class="swiper-wrapper">
                <div
                  v-for="(item, teacherIdx) in teacherLoopList"
                  :key="teacherIdx"
                  class="swiper-slide"
                  @click="getThisTeacher(item.id, item)"
                >
                  <img
                    class="icon-active"
                    :src="
                      item.avatar
                        ? item.avatar
                        : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                    "
                    alt=""
                  />
                  <div>{{ item.realName }}{{ item.teacherTitle }}</div>
                </div>
              </div>
              <div class="swiper-button-prev teacher-prev"></div>
              <div class="swiper-button-next teacher-next"></div>
            </div>
          </div>
          <div v-for="(item, i) in dataShow" :key="i">
            <div class="right-content">
              <div class="main-title sflex sflex-ac sflex-jsp">
                <div class="taecher-content sflex sflex-as sflex-grow">
                  <div class="content-img-box sflex sflex-ac sflex-y">
                    <div class="head-img">
                      <img
                        class="fit-img-cover"
                        :src="
                          item.avatar
                            ? item.avatar
                            : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                        "
                        alt=""
                      />
                    </div>
                    <div class="title-img">
                      <img
                        class="fit-img-cover"
                        src="../assets/images/teacher_title.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="teac-synopsis">
                    <div class="sflex sflex-ac">
                      <div class="teac-name ut-s">
                        {{ item.realName }}{{ item.teacherTitle }}
                      </div>
                      <div
                        v-for="(consultModeItem, n) in item.consultMode"
                        :key="n"
                        class="teac-attr"
                      >
                        {{
                          consultModeItem == '1'
                            ? '图文'
                            : consultModeItem == '2'
                            ? '电话'
                            : '线下'
                        }}
                      </div>
                    </div>
                    <div class="sflex sflex-ac teac-location">
                      <img
                        src="../assets/images/major_location.png"
                        class="location-icon"
                        alt=""
                      />
                      <div class="ut-s2">
                        {{ item.provinceName }}{{ item.cityName }}
                      </div>
                    </div>
                    <div class="teac-txt">
                      {{ item.teacherName }}<br />
                      {{ item.teacherDescribe }}
                    </div>
                  </div>
                  <div class="teac-classify sflex sflex-ac sflex-wrap">
                    <!-- <div class="classify-item">专业咨询</div> -->
                    <div
                      v-for="(tagItem, r) in item.tagArr"
                      :key="r"
                      class="classify-item"
                    >
                      {{ tagItem }}
                    </div>
                  </div>
                </div>
                <div class="main-btn">扫码咨询</div>
              </div>
              <div class="main-content sflex">
                <div class="content-left">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">教育（工作）背景</div>
                  </div>
                  <div class="details">
                    <div
                      v-for="(expItem, j) in item.exp"
                      :key="j"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        {{ expItem }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-center">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">线上咨询</div>
                  </div>
                  <div class="details">
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">专业：</span>
                        <br />
                        <div
                          v-for="(specialityItem, k) in item.specialityList"
                          :key="k"
                        >
                          <span class="text-blue">
                            {{ specialityItem.code
                            }}{{ specialityItem.categoryName }}：
                          </span>
                          <span
                            v-for="(
                              specialityChildItem, m
                            ) in specialityItem.childList"
                            :key="m"
                            >{{ specialityChildItem.categoryName }}
                            <span
                              v-if="specialityItem.childList.length - 1 > m"
                              class="text-blue"
                              >/&nbsp;</span
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">学校：</span>
                        <span class="text-tips">(个人观点，非官方)</span>
                        <br />
                        <div v-if="item.schoolArr.length > 0">
                          <div
                            v-for="(schoolItem, e) in item.schoolArr"
                            :key="e"
                          >
                            <span class="text-blue">
                              {{ schoolItem.name }}：
                            </span>
                            <span
                              v-for="(
                                schoolChildItem, u
                              ) in schoolItem.collegeArr"
                              :key="u"
                              >{{ schoolChildItem.name }}
                              <span
                                v-if="
                                  schoolChildItem.subjectTypeList.length > 0
                                "
                              >
                                <span>（</span>
                                <span
                                  v-for="(
                                    subjectTypeItem, p
                                  ) in schoolChildItem.subjectTypeList"
                                  :key="p"
                                >
                                  <span>{{
                                    subjectTypeItem.id === '1'
                                      ? '高考'
                                      : subjectTypeItem.id === '2'
                                      ? '考研'
                                      : subjectTypeItem.id === '3'
                                      ? '考博'
                                      : '暂无'
                                  }}</span>
                                  <span
                                    v-if="
                                      schoolChildItem.subjectTypeList.length -
                                        1 >
                                      p
                                    "
                                    class="text-blue"
                                    >&nbsp;/&nbsp;</span
                                  ></span
                                >
                                <span>）</span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        item.zerConsultScopeList &&
                        item.zerConsultScopeList.length > 0
                      "
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">
                        <span class="text-blue text-bold">其他：</span>
                        <br />
                        <span
                          v-for="(
                            zerConsultScopeItem, h
                          ) in item.zerConsultScopeList"
                          :key="h"
                          >{{ zerConsultScopeItem.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-right">
                  <div class="title sflex sflex-ac">
                    <img
                      src="../assets/images/major_details_1.png"
                      alt=""
                      class="title-img"
                    />
                    <div class="ut-s">
                      线下指导
                      <span v-if="item.location">（{{ item.location }}）</span>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList && item.zerGuideInfoList.length > 0
                    "
                    class="details"
                  >
                    <div
                      v-for="(zerGuideInfoItem, l) in item.zerGuideInfoList"
                      :key="l"
                      class="details-item sflex"
                    >
                      <div class="dot"></div>
                      <div class="sflex-grow">{{ zerGuideInfoItem.name }}</div>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.zerGuideInfoList === null ||
                      item.zerGuideInfoList.length == 0
                    "
                    class="details"
                  >
                    <div class="details-item sflex">
                      <div class="dot"></div>
                      <div class="sflex-grow">暂无</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="i == 0 && isCurrentTeacherInfo && currentPage === 1"
              class="major-banner"
            >
              <img
                src="../assets/images/major_banner.png"
                class="fit-img-cover"
                alt=""
              />
            </div>
          </div>

          <div
            v-if="dataShow.length > 0"
            class="major-pagination sflex sflex-jc"
          >
            <div class="block">
              <el-pagination
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="teacherListTotal"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      specialityList: [],
      allCategroyArr: [],
      allCategroyArrForDepth2: [],
      specialityArr: [],
      teacherList: [],
      subjectTypeStr: '',
      teacherListTotal: 0,
      thirdSpecialityList: [],
      currentCategoryName: '',
      search: '',
      totalPageData: [], // 数组内套数组，每个数组就是一页数据
      currentPage: 1, // 当前显示页码
      pageSize: 10, // 每页显示的数量
      pageNum: 1, // 总页数
      total: 0, // 总数量
      dataShow: [], // 当前要显示的数据
      teacherListNew: [],
      teacherLoopList: [],
      isCurrentTeacherInfo: false,
    }
  },
  created() {
    this.subjectType = this.$route.query.subjectType
    this.teacherId = this.$route.query.teacherId
    if (this.$route.query.specialityName !== '') {
      this.currentCategoryName = this.$route.query.specialityName // 专业类名
      this.currentCategoryParentId = this.$route.query.parentId // 专业类名父级id
    }
    this.subjectTypeStr = this.subjectType === '1' ? '高职' : '高校'
    if (this.getUrlParamsLength() === 1 && this.$route.query.teacherId) {
      this.getList(this.subjectType, true) // 加载专业分类列表
    } else {
      this.getList(this.subjectType, false) // 加载专业分类列表
    }

    if (this.teacherId !== '') {
      this.getTeacherInfo(this.teacherId)
    }
  },
  methods: {
    // 获取地址栏的参数数组
    getUrlParamsLength() {
      let url = decodeURIComponent(window.location.href) // 获取url中"?"符后的字串
      let theRequest = {}
      if (url.indexOf('?') !== -1) {
        let str = url.substr(url.indexOf('?') + 1)
        let strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
        }
      }
      return Object.getOwnPropertyNames(theRequest).length
    },

    getSearch() {
      if (this.search === '') {
        this.getSpecialityListByName(this.currentCategoryName)
      } else {
        let parentId = ''
        this.allCategroyArr.forEach((item) => {
          if (this.search === item.categoryName) {
            parentId = item.parentId
            // if (item.categoryDepth === 1) {
            //   this.getThird(item.id, item.parentId, item.categoryName, 1)
            // } else {
            //   this.getThird(item.id, item.parentId, item.categoryName, 3)
            // }
          }
        })
        this.allCategroyArrForDepth2.forEach((item) => {
          if (parentId === item.id) {
            this.getThird(item.id, item.parentId, item.categoryName, 3)
          }
        })
      }
    },
    getList(subjectType, onlyTeac = false) {
      // 获取专业分类列表
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        subjectType: subjectType, // 类型（1-专科、 2-本科）
      }
      this.$apis.getSpecialityList(obj).then((response) => {
        if (response.data.code === 200) {
          this.specialityList = response.data.rows
          let specialityArr = []
          this.allCategroyArr = [] // 获取学科
          this.allCategroyArrForDepth2 = [] // 获取第二级分类数组
          for (let i = 0; i < this.specialityList.length; i++) {
            this.specialityList[i].isCheck = false
            if (this.specialityList[i].parentId === '0') {
              specialityArr.push(this.specialityList[i])
              this.specialityList[i].childList = []

              for (let j = 0; j < this.specialityList.length; j++) {
                if (
                  this.specialityList[j].parentId === this.specialityList[i].id
                ) {
                  this.specialityList[i].childList.push(this.specialityList[j])
                }
              }
            }

            if (this.specialityList[i].categoryDepth === 3) {
              this.allCategroyArr.push(this.specialityList[i])
            }
            if (this.specialityList[i].categoryDepth === 2) {
              this.allCategroyArrForDepth2.push(this.specialityList[i])
            }
          }

          if (onlyTeac) {
            specialityArr.forEach((item, i) => {
              if (item.id === this.currentCategoryParentId) {
                index = i
              }
              item.childListNew = []
              item.childList.forEach((child) => {
                if (this.currentCategoryName === child.categoryName) {
                  id = child.id
                }
              })
            })
            this.specialityArr = specialityArr
            this.getThird('', '', '', 1, onlyTeac)
          } else {
            if (
              this.currentCategoryName === '' ||
              this.currentCategoryName === undefined ||
              this.currentCategoryName === null
            ) {
              // 默认选中第一个分类
              specialityArr[0].isCheck = true
              specialityArr.forEach((item) => {
                item.childListNew = []
              })
              this.specialityArr = specialityArr
              this.getThird(
                specialityArr[0].id,
                specialityArr[0].parentId,
                specialityArr[0].categoryName,
                1,
                onlyTeac
              )
              this.currentCategoryName = specialityArr[0].categoryName
            } else {
              let index = 0
              let id = 0
              specialityArr.forEach((item, i) => {
                if (item.id === this.currentCategoryParentId) {
                  index = i
                }
                item.childListNew = []
                item.childList.forEach((child) => {
                  if (this.currentCategoryName === child.categoryName) {
                    id = child.id
                  }
                })
              })
              specialityArr[index].isCheck = true
              this.specialityArr = specialityArr
              this.getThird(
                specialityArr[index].id,
                specialityArr[index].parentId,
                this.currentCategoryName,
                1,
                onlyTeac
              )
              this.getThird(
                id,
                specialityArr[index].id,
                this.currentCategoryName,
                0,
                onlyTeac
              )
            }
          }
        }
      })
    },

    // 通过教师id获取列表
    getTeacherInfo(teacherId) {
      let obj = {
        id: teacherId,
      }
      this.$apis.getTeacherInfo(obj).then((response) => {
        if (response.data.code === 200) {
          let item = response.data.data
          if (item.exp && item.exp !== null) {
            if (item.exp.indexOf('|') !== -1) {
              item.exp = item.exp.split('|')
            } else {
              let arr = []
              arr[0] = item.exp
              item.exp = arr
            }
          }
          if (item.consultMode && item.consultMode !== null) {
            if (item.consultMode.indexOf(',') !== -1) {
              item.consultMode = item.consultMode.split(',')
            } else {
              let arr = []
              arr[0] = item.consultMode
              item.consultMode = arr
            }
          }
          if (item.zerGuideInfoList && item.zerGuideInfoList.length > 0) {
            item.location =
              item.zerGuideInfoList[0].provinceName +
              item.zerGuideInfoList[0].cityName
          } else {
            item.location = ''
          }
          // 获取教师标签
          item.tagArr = this.commit.getTeacherTags(item)
          // 获取教师学校
          item.schoolArr = this.commit.getTeacherSchool(item)
          this.currentTeacherInfo = item
        }
      })
    },

    // 通过专业找老师
    getSpecialityListByName(specialityName, type) {
      this.specialityName = specialityName
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        // specialityParentName: this.specialityName,
        // specialityName: this.specialityName,
        status: '1',
      }
      if (type === 1) {
        obj.specialityNames = this.specialityName
      } else {
        obj.specialityParentName = this.specialityName
      }
      this.$apis.getSpecialityListByName(obj).then((response) => {
        if (response.data.code === 200) {
          let teacherArr = response.data.rows
          teacherArr.forEach((item) => {
            if (item.exp && item.exp !== null) {
              if (item.exp.indexOf('|') !== -1) {
                item.exp = item.exp.split('|')
              } else {
                let arr = []
                arr[0] = item.exp
                item.exp = arr
              }
            }
            if (item.consultMode && item.consultMode !== null) {
              if (item.consultMode.indexOf(',') !== -1) {
                item.consultMode = item.consultMode.split(',')
              } else {
                let arr = []
                arr[0] = item.consultMode
                item.consultMode = arr
              }
            }
            if (item.zerGuideInfoList && item.zerGuideInfoList.length > 0) {
              item.location =
                item.zerGuideInfoList[0].provinceName +
                item.zerGuideInfoList[0].cityName
            } else {
              item.location = ''
            }
            // 获取教师标签
            item.tagArr = this.commit.getTeacherTags(item)
            // 获取教师学校
            item.schoolArr = this.commit.getTeacherSchool(item)
          })
          this.teacherListNew = []
          if (specialityName === this.$route.query.specialityName) {
            if (this.currentTeacherInfo) {
              this.isCurrentTeacherInfo = true
              if (teacherArr.length > 0) {
                this.teacherListNew = [this.currentTeacherInfo, ...teacherArr]
                this.teacherListNew = this.unique(this.teacherListNew)
              } else {
                this.teacherListNew = [this.currentTeacherInfo]
              }
            } else {
              this.teacherListNew = teacherArr
            }
          } else {
            this.teacherListNew = teacherArr
            this.isCurrentTeacherInfo = false
            this.teacherList = this.teacherListNew
          }
          this.teacherListTotal = response.data.total
          this.teacherLoopList = JSON.parse(JSON.stringify(this.teacherListNew))

          this.$nextTick(() => {
            this.initSwiper()
          })
          // 计算页数
          this.calcPageData()

          this.isCurrentTeacherInfo = false
        }
      })
    },

    initSwiper() {
      new Swiper('.teacher-swiper', {
        slidesPerView: 6,
        spaceBetween: 40,
        initialSlide: 1,
        observer: true,
        observeParents: false,
        // grid: {
        //   rows: 2,
        //   fill: 'row'
        // },
        // spaceBetween: 30,
        // 如果需要前进后退按钮
        nextButton: '.teacher-next',
        prevButton: '.teacher-prev',
        onSlideChangeEnd: function (swiper) {
          swiper.update()
        },
        breakpoints: {
          320: {
            // 当屏幕宽度大于等于320
            slidesPerView: 1,
            // spaceBetween: 10
          },
          768: {
            // 当屏幕宽度大于等于768
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1280: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1550: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1600: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1700: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1800: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 35,
          },
          2500: {
            // 当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 40,
          },
        },
      })
      this.$nextTick(() => {
        this.$refs.teacherSwiper.swiper.slideTo(0, 300, false)
      })
    },

    // 获取第三级分类专业
    getThird(id, parentId, categoryName, type = 0, onlyTeac = false) {
      this.currentCategoryName = categoryName
      // this.search = ''
      for (let i = 0; i < this.specialityList.length; i++) {
        this.specialityList[i].isCheck = false
        if (this.specialityList[i].id === id) {
          this.specialityList[i].isCheck = true
        }
        if (parentId !== '0' && this.specialityList[i].id === parentId) {
          this.specialityList[i].isCheck = true
        }
      }
      if (type === 1 || type === 3) {
        this.specialityArr.forEach((item) => {
          item.childListNew = []
          if (type === 1) {
            if (item.id === id) {
              item.childListNew = item.childList
              item.childListNew.forEach((childItem) => {
                if (childItem.categoryName === categoryName) {
                  childItem.isCheck = true
                }
              })
            }
          }
          if (type === 3) {
            if (item.id === parentId) {
              item.childListNew = item.childList
              item.childListNew.forEach((childItem) => {
                if (childItem.categoryName === categoryName) {
                  childItem.isCheck = true
                }
              })
            }
          }
        })
      }

      if (!onlyTeac) {
        this.getSpecialityListByName(categoryName, type)

        this.thirdSpecialityList = []
        for (let i = 0; i < this.specialityList.length; i++) {
          if (this.specialityList[i].parentId === id) {
            this.thirdSpecialityList.push(this.specialityList[i])
          }
        }
      } else {
        this.teacherListNew = []
        this.teacherListNew.push(this.currentTeacherInfo)
        this.teacherLoopList = JSON.parse(JSON.stringify(this.teacherListNew))
        // 计算页数
        this.calcPageData()
      }
    },

    // 选中教师轮播模块对应数组移至首位
    getThisTeacher(id, obj) {
      for (var i = 0; i < this.teacherListNew.length; i++) {
        if (this.teacherListNew[i].id === id) {
          this.teacherListNew.splice(i, 1) // 如果数据组存在该元素，则把该元素删除
          break
        }
      }
      this.teacherListNew.unshift(obj) // 再添加到第一个位置
      this.isCurrentTeacherInfo = true
      this.calcPageData()
    },

    // 计算页数
    calcPageData() {
      if (this.teacherListNew.length > 1) {
        this.pageNum =
          Math.ceil(this.teacherListNew.length / this.pageSize) || 1
      }
      for (let i = 0; i < this.pageNum; i++) {
        // 每一页都是一个数组,根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为10， 则第一页是1-10条，即slice(0,10)
        this.totalPageData[i] = this.teacherListNew.slice(
          this.pageSize * i,
          this.pageSize * (i + 1)
        )
      }
      // 获取到数据后显示第一页内容,数组下标是从0开始,所以要减1
      this.dataShow = this.totalPageData[this.currentPage - 1]
      this.$forceUpdate()
    },
    // 每页显示的条数改变时候触发
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize
      this.calcPageData()
    },
    // 当前页改变时候触发
    handleCurrentChange(newPageSize) {
      this.currentPage = newPageSize
      this.dataShow = this.totalPageData[newPageSize - 1]
    },

    // 跳转到首页
    goToIndex() {
      this.$router.push({
        path: '/',
      })
    },
    // 跳转到选专业
    goToMajor() {
      this.$router.push({
        path: '/Major',
      })
    },

    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
  },
}
</script>

<style lang="less" scoped>
/*下拉框最后一个显示不完全*/
/deep/.el-select-dropdown__wrap.el-scrollbar__wrap {
  margin-bottom: 0 !important;
  text-align: left !important;
}
/deep/.el-input {
  height: 2.05rem;
}
.choose-major-modal {
  background: #f6f7f9;
  .text-blue {
    color: #4861f5;
  }
  .text-bold {
    font-weight: bold;
  }
  .choose-major-head {
    width: 100%;
    height: 5rem;
    background: url(../assets/images/choose_major_head.png) no-repeat;
    background-size: 100% 100%;
    .choose-major-inp {
      width: 33.5rem;
      height: 2.05rem;
      border-radius: 0rem;
      background: #ffffff;
      border-radius: 0.35rem;
      padding: 0 0.9rem;
      font-size: 0.75rem;
      font-family: Microsoft YaHei;
      color: #555555;
      margin-right: 0.4rem;
    }
    .search-btn {
      width: 6.95rem;
      height: 2.05rem;
      line-height: 2.05rem;
      background: linear-gradient(0deg, #00bcd4, #82f1ff);
      border-radius: 0.35rem;
      font-size: 0.9rem;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0.35);
      cursor: pointer;
    }
  }
  .choose-major-main {
    margin: 0 14%;
    .main-bread {
      font-size: 0.75rem;
      font-weight: 400;
      color: #787878;
      padding: 1rem 0 1.1rem;
      img {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.35rem;
      }
      .active {
        color: #333333;
      }
    }
    .main {
      .main-left {
        // width: 17.5%;
        width: 13rem;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        overflow: hidden;
        .left-title {
          background: url(../assets/images/choose_major_title.png) no-repeat;
          background-size: 100% 100%;
          height: 4.1rem;
          font-size: 1rem;
          font-weight: bold;
          color: #ffffff;
          padding: 0 1rem;
        }
        .left-content {
          text-align: left;
          background: #ffffff;
          padding: 0.75rem;
          max-height: 80vh;
          overflow: hidden;
          overflow-y: auto;
          transition: all 2s;
          .left-item {
            padding-bottom: 0.55rem;
            .item-title {
              height: 1.55rem;
              line-height: 1.55rem;
              border-radius: 0.25rem;
              font-size: 0.75rem;
              font-weight: bold;
              color: #333333;
              background: #f6f6f6;
              padding: 0 0.55rem;
              // margin-bottom: 0.5rem;
              cursor: pointer;
            }
            .item-title.active {
              background: #465ff7;
              color: #ffffff;
            }
            .item-content-box {
              padding-top: 0.55rem;
            }
            .item-content {
              font-size: 0.75rem;
              font-weight: 400;
              color: #787878;
              padding: 0 0.55rem 0.2rem;
              cursor: pointer;
            }
            .item-content.active {
              color: #4760f6;
            }
            .item-content:hover {
              .active {
                color: #4760f6;
              }
            }
            .item-content:last-child {
              padding: 0 0.55rem;
            }
          }
        }

        .left-content::-webkit-scrollbar {
          width: 0px;
        }
      }
      .main-right {
        width: calc(100% - 13rem - 1rem);
        text-align: left;
        .right-title {
          width: 100%;
          // height: 4.7rem;
          border: 1px solid #9cc4fe;
          box-shadow: 0rem 0rem 1rem 0rem rgba(99, 161, 253, 0.35);
          border-radius: 0.35rem;
          margin-bottom: 0.55rem;
          .title-icon {
            background: url(../assets/images/major_title.png);
            background-size: 100% 100%;
            line-height: 1.25rem;
            width: 2.4rem;
            height: 1.25rem;
            font-size: 0.75rem;
            color: #ffffff;
            margin: 1rem 0.65rem;
            padding-left: 0.3rem;
          }
          .title-content {
            color: #666666;
            font-size: 0.75rem;
            width: 100%;
            margin: 1rem 0;
            padding-right: 1rem;
          }
          .line {
            color: #455ef7;
            font-size: 0.9rem;
            line-height: 1.25rem;
          }
        }
        .right-content {
          box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.02);
          border-radius: 0.35rem;
          background: #fff;
          margin-bottom: 0.5rem;
          .main-title {
            padding: 1.25rem 0;
            margin: 0 1rem;
            border-bottom: 1px solid #f2f3f4;
            .taecher-content {
              .content-img-box {
                margin-bottom: 0.25rem;
                .head-img {
                  width: 4rem;
                  height: 4rem;
                  border-radius: 50%;
                  overflow: hidden;
                }
                .title-img {
                  width: 4.25rem;
                  height: 1.2rem;
                  margin-top: 0;
                  z-index: 2;
                }
              }
              .teac-classify {
                margin-left: 1.6rem;
              }
            }
            .main-btn {
              width: 6.6rem;
              min-width: 6.6rem;
              height: 2.05rem;
              line-height: 2.05rem;
              text-align: center;
              background: linear-gradient(90deg, #425df6, #6a81f8);
              box-shadow: 0rem 0rem 1rem 0rem rgb(71 98 248 / 46%);
              border-radius: 0.25rem;
              // padding: 0 1.75rem;
              font-size: 0.8rem;
              color: #f6f7f9;
              margin-left: 1rem;
            }
          }
          .main-content {
            padding: 0.55rem 0.7rem;
            .content-left {
              position: relative;
              width: 34.8%;
              padding-right: 1rem;
            }
            .content-left::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-center {
              position: relative;
              width: 37.8%;
              padding-left: 0.8rem;
              padding-right: 1rem;
            }
            .content-center::after {
              position: absolute;
              width: 1px;
              top: 1.1rem;
              right: 0;
              height: calc(100% - (1.1rem + 2.75rem));
              content: '';
              background-image: linear-gradient(
                to bottom,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              );
            }
            .content-right {
              width: 27.4%;
              padding-left: 0.8rem;
            }
            .title {
              font-size: 0.8rem;
              font-weight: bold;
              color: #333333;
              .title-img {
                width: 1.65rem;
                height: 1.65rem;
                border-radius: 50%;
                margin-right: 0.45rem;
              }
            }
            .details {
              padding: 0.6rem 0;
              .details-item {
                font-size: 0.75rem;
                color: #555555;
                line-height: 1.3rem;
                margin: 0 0.6rem;
                .dot {
                  max-width: 0.35rem;
                  max-height: 0.35rem;
                  min-width: 0.35rem;
                  min-height: 0.35rem;
                  width: 0.35rem;
                  height: 0.35rem;
                  border-radius: 50%;
                  background: #63a1fd;
                  margin-top: 0.4rem;
                  margin-right: 0.45rem;
                }
                .text-tips {
                  font-size: 0.65rem;
                }
              }
            }
          }
        }
        .major-banner {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 8%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .choose-major-modal {
    .choose-major-main {
      margin: 0 4%;
      .main-right {
        .right-content {
          .main-title {
            .taecher-content {
              flex-wrap: wrap;
              .teac-synopsis {
                width: calc(100% - 5.25rem);
              }
              .teac-classify {
                width: 100%;
                flex-grow: 1;
                margin-left: 0 !important;
                margin-top: 0.5rem !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .choose-major-modal {
    .choose-major-head {
      padding: 0 4%;
    }
    .choose-major-main {
      margin: 0 1rem !important;
      .main-left {
        width: 10rem !important;
      }
      .main-right {
        width: calc(100% - 10rem - 1rem) !important;
        .right-content {
          .main-title {
            flex-direction: column;
          }
          .main-content {
            flex-direction: column;
            .content-left {
              width: 100% !important;
              padding-right: 0 !important;
            }
            .content-left::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-center {
              width: 100% !important;
              margin-top: 0.5rem;
            }
            .content-center::after {
              position: absolute;
              width: calc(100% - 2rem) !important;
              height: 1px !important;
              top: auto !important;
              bottom: 0 !important;
              right: 1rem !important;
              content: '';
              background-image: linear-gradient(
                to right,
                #f4f4f4 0%,
                #e1e1e1 30%,
                #e1e1e1 70%,
                #f4f4f4 100%,
                transparent
              ) !important;
            }
            .content-right {
              width: 100% !important;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
