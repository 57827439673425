<template>
  <div class="news">
    <img class="model-bg" src="../assets/images/news_bg.png" alt="" />
    <div class="news-nav sflex sflex-ac news-container">
      <div class="nav-item active">招生政策（高考）</div>
      <div class="nav-item">省市新闻（高考）</div>
      <div class="nav-item">国家政策（考研）</div>
      <div class="nav-item">院校政策（考研）</div>
    </div>
    <div class="news-main news-container">
      <div
        v-for="i of 5"
        :key="i"
        class="main-item sflex sflex-ac sflex-wrap"
        @click="goToNewsDetails()"
      >
        <div class="item-left sflex">
          <div class="date">04-15</div>
          <div class="line">/</div>
        </div>
        <div class="item-content sflex-1">
          <div class="content-title ut-s">
            教育部成立世界职业技术教育发展大会组
          </div>
          <div class="content-txt ut-s2">
            经国务院同意，教育部将在天津举办世界职业技术教育发展大会。近期，教育部成立了由教育部、天津市人民政府主要负责同志担任主任委员的大会组织委员会。
          </div>
        </div>
        <img class="item-right" src="../assets/images/news_img1.png" alt="" />
      </div>
    </div>
    <div class="major-pagination sflex sflex-jc">
      <div class="block">
        <el-pagination
          :page-size="10"
          layout="prev, pager, next"
          :total="100"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsDetails',
  methods: {
    // 跳转到选专业
    goToNewsDetails() {
      this.$router.push({
        path: '/NewsDetails'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.news {
  .news-container {
    padding: 0 11.85rem;
    width: 100%;
  }
  .news-nav {
    height: 2.7rem;
    border-bottom: 1px solid #e2e2e3;
    .nav-item {
      height: 2.7rem;
      line-height: 2.7rem;
      font-size: 0.9rem;
      color: #555555;
      margin-right: 3rem;
    }
    .nav-item.active {
      position: relative;
      color: #5771e5;
    }
    .nav-item.active::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: calc(50% - 0.8rem);
      width: 1.6rem;
      height: 0.35rem;
      background: linear-gradient(86deg, #455ef8, #5b76e0);
      border-radius: 0.15rem;
    }
  }
  .news-main {
    .main-item {
      padding: 2.05rem 0 2.4rem;
      border-bottom: 1px dashed #e2e2e3;
      .date {
        position: relative;
        width: 11.05rem;
        height: 5.45rem;
        line-height: 5.45rem;
        border: 1px solid #e2e2e3;
        border-right: 0;
        font-size: 2.5rem;
        font-family: LXGWFasmartGothic;
        color: #333333;
        font-weight: 400;
      }
      .date::before,
      .date::after {
        position: absolute;
        content: "";
        background: #e2e2e3;
        width: 1px;
        height: 1.2rem;
        right: 0;
      }
      .date::before {
        top: 0;
      }
      .date::after {
        bottom: 0;
      }
      .line {
        margin: 3rem 1.25rem 0 2.2rem;
        font-size: 1.2rem;
        color: #cfcfcf;
        line-height: 1.2rem;
      }
      .item-content {
        text-align: left;
      }
      .content-title {
        font-size: 0.9rem;
        font-weight: bold;
        color: #333333;
        line-height: 1.2rem;
        margin-bottom: 1.3rem;
      }
      .content-txt {
        font-size: 0.75rem;
        color: #727272;
        line-height: 1.2rem;
      }
      .item-right {
        width: 13.05rem;
        min-width: 13.05rem;
        height: 7.8rem;
        margin-left: 2.5rem;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .news {
    .news-container {
      padding: 0 10%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .news {
    .news-nav {
      .nav-item {
        margin-right: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .news {
    .news-nav {
      padding: 0 2%;
      .nav-item {
        margin-right: 0;
      }
    }
    .news-main {
      .main-item {
        .line {
          display: none;
        }
        .item-left,
        .item-content,
        .item-right {
          text-align: center;
          width: 100%;
          margin: 0 auto;
          height: auto;
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
