<template>
  <div class="major-wrap">
    <div class="major-modal">
      <div class="major-head-box container">
        <div class="head-top sflex sflex-jsp">
          <div class="head-left sflex-grow">
            <img src="../assets/images/major_head3.jpg" alt="" />
          </div>
          <div class="head-right">
            <div class="major-search-box sflex sflex-ac">
              <input
                v-model="searchValue"
                type="text"
                class="major-inp sflex-1"
                placeholder="输入关键词搜索"
              />
              <div class="search-btn sflex sflex-ac" @click="getAllList()">
                <img
                  class="search-icon"
                  src="../assets/images/major_search_icon.png"
                  alt=""
                />
              </div>
            </div>
            <div class="major-tip-box sflex sflex-ac">
              <img src="../assets/images/major_tip_icon.png" alt="" />
              <div class="tip-title">搜索建议</div>
            </div>
            <div class="search-content-box sflex sflex-ac sflex-jsp sflex-wrap">
              <div
                v-for="(item, q) in dicList"
                :key="q"
                class="search-content ut-s"
                :class="{ active: item.isCheck }"
                @click="getDicInfo(item.dictValue)"
              >
                {{ item.dictValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 按学校 高校开始 -->
      <div class="major-main-box container">
        <div v-for="(subject, i) in navList1" :key="i" class="swiper-box">
          <div class="main-title sflex sflex-ac sflex-jsp">
            <div class="main-title-text sflex">
              <!-- 中学 -->
              <div class="text-blue">
                {{ subject.dictValue }}
              </div>
              学习经验分享
            </div>
            <div
              class="title-more"
              @click="goToScoreDetails(subject.dictValue)"
            >
              <p>更多</p>
              <img src="../assets/images/major_title_more.png" alt="" />
            </div>
          </div>
          <div class="main-swiper">
            <div class="school-universities-swiper swiper-1">
              <!-- <div class="swiper-wrapper">
                <div
                  v-for="teachIndex of subject.teachlistLength"
                  :key="teachIndex"
                  class="swiper-slide sflex sflex-wrap"
                >
                  <div
                    v-for="(item, g) in subject.teachList.slice(
                      (teachIndex - 1) * 6,
                      6 * teachIndex
                    )"
                    :key="g"
                    class="score-item"
                    @click="goToScoreDetails(subject.dictValue, item.realName)"
                  >
                    <div class="school-brief">
                      <div class="item-top sflex">
                        <div class="content-img-box sflex sflex-ac sflex-y">
                          <div class="head-img">
                            <img
                              class="fit-img-cover"
                              :src="item.avatar"
                              alt=""
                            />
                          </div>
                          <div class="title-img">
                            <img
                              class="fit-img-cover"
                              src="../assets/images/teacher_title.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="teac-synopsis">
                          <div class="sflex sflex-ac">
                            <div class="teac-name ut-s">
                              {{ item.realName }}
                            </div>
                            <div
                              v-for="(consultModeItem, n) in item.consultMode"
                              :key="n"
                              class="teac-attr"
                            >
                              {{
                                consultModeItem == "1"
                                  ? "图文"
                                  : consultModeItem == "2"
                                  ? "电话"
                                  : "线下"
                              }}
                            </div>
                          </div>
                          <div class="ut-s2 teac-txt">
                            {{ item.teacherName }}<br />
                            {{ item.teacherDescribe }}
                          </div>
                        </div>
                      </div>
                      <div class="item-bottom sflex">
                        <div
                          v-if="
                            item.specialityList &&
                            item.specialityList.length > 0
                          "
                          class="teac-classify sflex sflex-wrap"
                        >
                          <div
                            v-for="(speciality, s) in item.specialityList"
                            :key="s"
                            class="classify-item"
                          >
                            {{ speciality.categoryName }}
                          </div>
                        </div>
                        <div v-else class="teac-classify sflex sflex-wrap">
                          暂无
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="swiper-wrapper">
                <div
                  v-for="(item, g) in subject.teachList"
                  :key="g"
                  class="swiper-slide score-item"
                  @click="goToScoreDetails(subject.dictValue, item.realName)"
                >
                  <div class="school-brief">
                    <div class="item-top sflex">
                      <div class="content-img-box sflex sflex-ac sflex-y">
                        <div class="head-img">
                          <img
                            class="fit-img-cover"
                            :src="
                              item.avatar
                                ? item.avatar
                                : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="title-img">
                          <img
                            class="fit-img-cover"
                            src="../assets/images/teacher_title.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="teac-synopsis">
                        <div class="sflex sflex-ac">
                          <div class="teac-name ut-s">
                            {{ item.realName }}{{ item.teacherTitle }}
                          </div>
                          <div class="teac-attr">图文</div>
                          <div class="teac-attr">线下</div>
                          <div class="teac-attr">电话</div>
                        </div>
                        <div class="teac-txt">
                          {{ item.teacherName }}<br />
                          {{ item.teacherDescribe }}
                        </div>
                      </div>
                    </div>
                    <div class="item-bottom sflex">
                      <div class="teac-classify sflex sflex-ac sflex-wrap">
                        <div
                          v-for="(tagItem, s) in item.tagArr"
                          :key="s"
                          class="classify-item"
                        >
                          {{ tagItem }}
                        </div>
                        <!-- <div class="classify-item">专业咨询</div>
                        <div class="classify-item">专业咨询</div>
                        <div class="classify-item">专业咨询</div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev school-universities-prev"></div>
              <div class="swiper-button-next school-universities-next"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 按学校 高校结束 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Major',
  data() {
    return {
      searchValue: '', // 搜索值
      collegeTeachersListBySchool: [], // 按学校找老师（高校）
      dicList: [], // 搜索建议
      navList: [], // 导航列表
      navList1: [], // 导航列表
    }
  },
  created() {
    // 获取搜索建议
    this.getDicList()

    // 获取科目导航信息
    this.getNavList()
  },
  mounted() {
    // this.getDictDatal('homepage_search_referral')
    // 加载选专业模块所有列表
    this.getAllList()
  },
  methods: {
    getNavList() {
      this.$apis.getDictDatal('good_subjects').then((response) => {
        this.navList = response.data.data
        // this.getTeacherList()

        this.navList.forEach((item) => {
          item.teachList = []
          item.teachlistLength = 0
        })
        this.navList.forEach((item, i) => {
          this.getTeacherList(item.dictValue, i)
        })
        this.$forceUpdate()
      })
    },
    // 获取对应老师的列表
    getTeacherList(goodSubjects, index = 0) {
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        status: 1,
        goodSubjects: goodSubjects,
        searchValue: this.searchValue,
      }
      // if (this.searchValue.indexOf('大学') !== -1) {
      //  obj.collegeId = this.searchValue
      // } else {
      //  obj.searchValue = this.searchValue
      // }

      this.$apis.getSpecialityListByName(obj).then((response) => {
        let aa = {
          age: null,
          appraisalList: [],
          avatar:
            'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKpgOYib8MqAYdytZg3HycP7jsomyBCqPbFMLUTQPB6yNejZNhibzn6rXoAhjQd8b1ic0gEficJpFwEzQ/132',
          balance: 0,
          birthday: null,
          cityId: null,
          cityName: '',
          collegeId: null,
          collegeProvinceId: '210000',
          collegeProvinceName: '辽宁省',
          consultMode: '1,2,3',
          consultName: '学习考试心理调适,专项计划（励志计划）,强基计划',
          consultType: null,
          createBy: null,
          createId: null,
          createName: 'jsfwb001',
          createTime: '2022-03-12 12:10:49',
          doctor: null,
          email: null,
          exp: '高中：内蒙古呼和浩特二中理科实验班|大学：清华大学 化工学院在读',
          freezeAmount: 0,
          gender: '0',
          goodSubjects: '中学数学,中学英语,中学政治',
          guideName: '高中生备考策略,高中生学习规划',
          headLine: null,
          id: 'dc0312bc-70fd-4fad-94fb-3d3236d0e198',
          identityAuth: null,
          identityCard: null,
          identityCardPath: null,
          imAccount: null,
          imagePath: null,
          inviteId: null,
          isRealPeople: 1,
          isReceiveMessage: 1,
          isRecommend: 0,
          isSetProfile: 0,
          isStudent: 0,
          loginUserId: '277',
          master: null,
          mobile: '18518536500',
          params: {},
          provinceId: null,
          provinceName: '',
          qualificationsPath: null,
          realName: '华伟本',
          rejectReason: null,
          remark: null,
          reviewCounts: 0,
          schoolList: null,
          searchValue: null,
          serviceCounts: 0,
          serviceLabel: null,
          settlementAmount: 0,
          specialityList: null,
          specialityName: null,
          starRating: 5,
          status: 0,
          teacherDescribe: '内蒙2000年高考化学满分',
          teacherName: '清华大学本科生',
          teacherTitle: '学长',
          teacherType: '2',
          undergraduate: null,
          updateBy: null,
          updateId: null,
          updateName: null,
          updateTime: '2022-03-22 12:41:35',
          videoPath: null,
          weChat: null,
          withdrawalsAmount: 0,
        }
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        // response.data.rows.push(aa)
        this.navList[index]['teachList'] = response.data.rows
        this.navList[index]['teachlistLength'] = Math.ceil(
          response.data.rows.length / 8
        )
        this.navList1 = []
        this.navList.forEach((item) => {
          if (item.teachList.length !== 0) {
            item.teachList.forEach((childItem) => {
              if (childItem.consultMode && childItem.consultMode !== null) {
                if (childItem.consultMode.indexOf(',') !== -1) {
                  childItem.consultMode = childItem.consultMode.split(',')
                } else {
                  let arr = []
                  arr[0] = childItem.consultMode
                  childItem.consultMode = arr
                }
              }
              // 获取教师标签
              childItem.tagArr = this.commit.getTeacherTags(childItem)
            })
            this.navList1.push(item)
          }
        })

        this.$nextTick(() => {
          // 按学校找老师（高校）
          new Swiper('.school-universities-swiper', {
            slidesPerView: 3,
            slidesPerColumnFill: 'row',
            slidesPerColumn: 2,
            // grid: {
            //   rows: 2,
            //   fill: 'row'
            // },
            // spaceBetween: 30,
            // 如果需要前进后退按钮
            nextButton: '.school-universities-next',
            prevButton: '.school-universities-prev',
            breakpoints: {
              320: {
                // 当屏幕宽度大于等于320
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                // 当屏幕宽度大于等于768
                slidesPerView: 1,
                spaceBetween: 30,
              },
              1280: {
                // 当屏幕宽度大于等于1280
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1550: {
                // 当屏幕宽度大于等于1280
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1600: {
                // 当屏幕宽度大于等于1280
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1800: {
                // 当屏幕宽度大于等于1280
                slidesPerView: 3,
                spaceBetween: 30,
              },
              2500: {
                // 当屏幕宽度大于等于1280
                slidesPerView: 3,
                spaceBetween: 30,
              },
            },
          })
        })
        this.$forceUpdate()
      })
    },
    // 获取搜索建议
    getDicList() {
      this.$apis.getDictDatal('homepage_search_referral').then((response) => {
        this.dicList = response.data.data
      })
    },
    // 点击搜索建议
    getDicInfo(dictValue) {
      this.searchValue = dictValue
      this.dicList.forEach((item) => {
        item.isCheck = false
        if (item.dictValue === dictValue) {
          item.isCheck = true
        }
      })
      this.getNavList()
    },

    // 获取选专业所有列表
    getAllList() {
      // this.getTeachersListBySchool('2')
      this.navList.forEach((item) => {
        item.teachList = []
        item.teachlistLength = 0
      })
      this.navList.forEach((item, i) => {
        this.getTeacherList(item.dictValue, i)
      })
      this.$forceUpdate()
    },
    // 按学校找老师
    getTeachersListBySchool(subjectType) {
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        // searchValue: this.searchValue, // 搜索值
        subjectType: subjectType, // 类型（1-专科、 2-本科）
      }
      if (this.searchValue.indexOf('大学') !== -1) {
        obj.collegeId = this.searchValue
      } else {
        obj.searchValue = this.searchValue
      }
      this.$apis.getTeachersListBySchool(obj).then((response) => {
        if (response.data.code === 200) {
          let arr = response.data.rows
          arr.forEach((item) => {
            if (item.level !== null && item.level !== '') {
              if (item.level.indexOf(',') !== -1) {
                item.levelArr = item.level.split(',')
              } else {
                let levelArr = []
                levelArr[0] = item.level
                item.levelArr = levelArr
              }
            }
            // 获取教师标签
            item.tagArr = this.commit.getTeacherTags(item)
          })
          // if (subjectType === '2') {
          //   this.collegeTeachersListBySchool = arr
          //   this.$nextTick(() => {
          //     // 按学校找老师（高校）
          //     new Swiper('.school-universities-swiper', {
          //       // loop: true,
          //       // slidesPerView: 3,
          //       // slidesPerView: 'auto',
          //       fill: 'column',
          //       rows: 2,
          //       // 如果需要前进后退按钮
          //       nextButton: '.school-universities-next',
          //       prevButton: '.school-universities-prev'
          //     })
          //   })
          // }
        }
      })
    },

    goToScoreDetails(dictValue, realname = '') {
      this.$router.push({
        path: '/ScoreDetails',
        query: { dictValue: dictValue, realname: realname },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.major-wrap {
  background: #f6f7f9;
  .major-modal {
    background: url(../assets/images/major_bg.png) no-repeat;
    background-size: 100%;
    .container {
      margin: 0 14%;
    }
    .major-head-box {
      // .head-top {
      //   margin-bottom: 0;
      // }
    }
    .major-main-box {
      .swiper-box {
        border-bottom: 1px solid #455ef7;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .swiper-box:last-child {
        border-bottom: 0;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .main-title {
        margin-bottom: 1rem;
      }
      .main-title-text {
        font-size: 1.3rem;
        color: #333333;
        .text-blue {
          position: relative;
          color: #4861f5;
          font-weight: bold;
        }
        .text-blue::after {
          position: absolute;
          content: '';
          bottom: -0.7rem;
          left: 0rem;
          width: 5.45rem;
          height: 1.4rem;
          background: url(../assets/images/major_title_bg.png) no-repeat;
        }
      }
      .swiper-1 {
        // 按专业
        // .score-item.swiper-slide {
        //   width: calc((100% - 2rem) / 3);
        //   margin-right: 1rem;
        // }
        // 按学校
        .score-item {
          // width: calc((100% - 2rem) / 3);
          margin-right: 1rem;
          align-items: stretch;
          margin-bottom: 1rem;
          cursor: pointer;
          .school-brief {
            border-radius: 0.35rem;
            background: #fff;
            padding: 1.1rem 0.7rem 0.6rem;
            text-align: left;
            .item-top {
              border-bottom: 1px solid #eeeff2;
              padding-bottom: 0.55rem;
              .school-img {
                width: 4.8rem;
                height: 4.8rem;
                margin-right: 1.2rem;
              }
              .teac-synopsis {
                margin-left: 0.8rem;
              }
            }
            .item-bottom {
              margin-top: 0.85rem;
              font-size: 0.75rem;
              color: #555555;
              .teac-classify {
                align-content: flex-start;
                height: 5.4rem;
                max-height: 5.4rem;
                overflow-y: auto;
              }
              /* 设置滚动条的样式 */
              .teac-classify::-webkit-scrollbar {
                width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }
              /* 滚动槽 */
              .teac-classify::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgb(255, 249, 249);
                border-radius: 5px;
                background: #ebe9e9;
              }
              /* 滚动条滑块 */
              .teac-classify::-webkit-scrollbar-thumb {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgb(255, 255, 255);
                background: #455ef7;
              }
              .teac-classify::-webkit-scrollbar-thumb:window-inactive {
                background: #455ef7;
                // box-shadow: rgba(255, 0, 0, 0.4);
              }
              .bottom-box {
                margin-right: 1rem;
              }
            }
          }
        }
        // .score-item:nth-child(3n) {
        //   margin-right: 0;
        // }
      }
    }
    .major-main-box:last-child {
      padding-bottom: 1.5rem;
    }
  }
}

// @media screen and (max-width: 1400px) {
//   .major-wrap {
//     .score-item.swiper-slide {
//       margin: 0 !important;
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .major-wrap {
    .score-item.swiper-slide {
      // margin: 0 !important;
      margin-bottom: 1rem !important;
      margin-top: 0rem !important;
      // margin-bottom: 0rem !important;
    }
  }
}
</style>

