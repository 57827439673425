<template>
  <div class="major-wrap">
    <div class="major-modal">
      <div class="major-head-box container">
        <div class="head-top sflex sflex-jsp">
          <div class="head-left sflex-grow">
            <img src="../assets/images/major_head1.jpg" alt="" />
          </div>
          <div class="head-right">
            <div class="major-search-box sflex sflex-ac">
              <input
                v-model="searchValue"
                type="text"
                class="major-inp sflex-1"
                placeholder="输入关键词搜索"
              />
              <div class="search-btn sflex sflex-ac" @click="getAllList()">
                <img
                  class="search-icon"
                  src="../assets/images/major_search_icon.png"
                  alt=""
                />
              </div>
            </div>
            <div class="major-tip-box sflex sflex-ac">
              <img src="../assets/images/major_tip_icon.png" alt="" />
              <div class="tip-title">搜索建议</div>
            </div>
            <div class="search-content-box sflex sflex-ac sflex-jsp sflex-wrap">
              <div
                v-for="(item, q) in dicList"
                :key="q"
                class="search-content ut-s"
                :class="{ active: item.isCheck }"
                @click="getDicInfo(item.dictValue)"
              >
                {{ item.dictValue }}
              </div>
            </div>
          </div>
        </div>
        <div class="head-bottom p-head-bottom">
          <div class="head-bottom-pc sflex sflex-ac">
            <img
              src="../assets/images/marjor_head_1.png"
              class="head-bottom-img"
              alt=""
            />
            <img
              src="../assets/images/marjor_head_2.png"
              class="head-bottom-img"
              alt=""
            />
            <img
              src="../assets/images/marjor_head_3.png"
              class="head-bottom-img"
              alt=""
            />
            <img
              src="../assets/images/marjor_head_4.png"
              class="head-bottom-img"
              alt=""
            />
            <img
              src="../assets/images/marjor_head_5.png"
              class="head-bottom-img"
              alt=""
            />
          </div>
          <div class="head-bottom-phone"></div>
        </div>
        <div class="m-head-bottom">
          <div class="major-head-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img
                  src="../assets/images/marjor_head_1.png"
                  class="head-bottom-img"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/marjor_head_2.png"
                  class="head-bottom-img"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/marjor_head_3.png"
                  class="head-bottom-img"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/marjor_head_4.png"
                  class="head-bottom-img"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/marjor_head_5.png"
                  class="head-bottom-img"
                  alt=""
                />
              </div>
            </div>
            <!-- <div class="swiper-button-prev major-universities-prev"></div>
            <div class="swiper-button-next major-universities-next"></div> -->
          </div>
        </div>
      </div>
      <!-- 按专业 高校开始 v-if="collegeTeachersListByMajor.length > 0" -->
      <div
        v-if="collegeTeachersListByMajor.length > 0"
        class="major-main-box container"
      >
        <div class="main-title sflex sflex-ac sflex-jsp">
          <div class="sflex">
            <img src="../assets/images/major_title1.png" alt="" />
            <div class="title-icon title-icon-blue">高校</div>
          </div>
          <div class="title-more" @click="goToMajorDetails(2)">
            <p>更多</p>
            <img src="../assets/images/major_title_more.png" alt="" />
          </div>
        </div>
        <div class="main-swiper">
          <div class="major-universities-swiper swiper-1">
            <div class="swiper-wrapper">
              <div
                v-for="(item, a) in collegeTeachersListByMajor"
                :key="a"
                class="swiper-slide major-item"
              >
                <div
                  class="item-top"
                  @click="
                    gotoDetailsForMajor(item.categoryName, item.parentId, 2, '')
                  "
                >
                  <div class="item-title">
                    {{ item.code }} {{ item.categoryName }}
                  </div>
                  <div class="sflex">
                    <div class="title-icon">专业</div>
                    <div class="sflex sflex-ac sflex-wrap title-category">
                      <div
                        v-for="(childItem, c) in item.childList"
                        :key="c"
                        class="sflex slex-ac"
                      >
                        {{ childItem.categoryName }}
                        <div v-if="item.childList.length - 1 > c" class="line">
                          &nbsp;/&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.teacherInfoList.length > 0"
                  class="item-content-box sflex sflex-ac sflex-wrap"
                >
                  <div
                    v-for="(teacherItem, b) in item.teacherInfoListNew"
                    :key="b"
                    class="item-centent"
                    @click="
                      gotoDetailsForMajor(
                        item.categoryName,
                        item.parentId,
                        2,
                        teacherItem.id
                      )
                    "
                  >
                    <div class="content-name-box sflex">
                      <div class="content-img-box sflex sflex-ac sflex-y">
                        <div class="head-img">
                          <img
                            class="fit-img-cover"
                            :src="
                              teacherItem.avatar
                                ? teacherItem.avatar
                                : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="title-img">
                          <img
                            class="fit-img-cover"
                            src="../assets/images/teacher_title.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="sflex-grow">
                        <div class="content-name ut-s2">
                          {{ teacherItem.realName
                          }}{{ teacherItem.teacherTitle }}
                        </div>
                        <div class="sflex sflex-ac content-location">
                          <img
                            src="../assets/images/major_location.png"
                            class="location-icon"
                            alt=""
                          />
                          <div class="ut-s2">
                            {{ teacherItem.provinceName
                            }}{{ teacherItem.cityName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="conetent-rank ut-s2">
                      {{ teacherItem.teacherName }}<br />{{
                        teacherItem.teacherDescribe
                      }}
                    </div>
                  </div>
                  <div
                    v-if="item.teacherInfoList.length > 4"
                    class="item-more sflex sflex-ac sflex-jc"
                    @click="
                      gotoDetailsForMajor(
                        item.categoryName,
                        item.parentId,
                        2,
                        ''
                      )
                    "
                  >
                    <div class="more-box sflex sflex-ac">
                      <img
                        src="../assets/images/major_more.png"
                        class="more-img"
                        alt=""
                      />
                    </div>
                    <div>更多...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev major-universities-prev"></div>
            <div class="swiper-button-next major-universities-next"></div>
          </div>
        </div>
      </div>
      <!-- 按专业 高校结束 -->
      <!-- 横幅开始 -->
      <div class="major-banner container">
        <img
          class="major-banner-img fit-img-cover"
          src="../assets/images/major_banner1.png"
          alt=""
        />
      </div>
      <!-- 横幅结束 -->
      <!-- 按学校 高校开始 -->
      <div
        v-if="collegeTeachersListBySchool.length > 0"
        class="major-main-box container"
      >
        <div class="main-title sflex sflex-ac sflex-jsp">
          <div class="sflex">
            <img src="../assets/images/major_title2.png" alt="" />
            <div class="title-icon title-icon-blue">高校</div>
          </div>
          <div class="title-more" @click="goToSchoolDetails(2, '', '')">
            <p>更多</p>
            <img src="../assets/images/major_title_more.png" alt="" />
          </div>
        </div>
        <div class="main-swiper">
          <div class="school-universities-swiper swiper-1">
            <div class="swiper-wrapper">
              <div
                v-for="(item, g) in collegeTeachersListBySchool"
                :key="g"
                class="swiper-slide school-item"
              >
                <div class="school-brief">
                  <div
                    class="item-top sflex"
                    @click="goToSchoolDetails(2, item.id, '', item.address)"
                  >
                    <div class="school-img">
                      <img class="fit-img-cover" :src="item.logo" alt="" />
                    </div>
                    <div class="item-title">
                      <div class="sflex sflex-ae">
                        <div class="name">{{ item.name }}</div>
                        <div class="sflex sflex-ac content-location">
                          <img
                            src="../assets/images/major_location.png"
                            class="location-icon"
                            alt=""
                          />
                          <div class="ut-s2">{{ item.address }}</div>
                        </div>
                      </div>
                      <div class="slogan">
                        校训：<span class="text-blue">{{ item.motto }}</span>
                      </div>
                      <div class="school-rank sflex sflex-ac sflex-wrap">
                        <div
                          v-for="(levelItem, h) in item.levelArr"
                          :key="h"
                          class="rank-item"
                        >
                          {{ levelItem }}
                        </div>
                        <!-- <div class="blue">双一流</div>
                        <div class="pink">985</div>
                        <div class="green">211</div>
                        <div class="purple">公办</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="item-bottom sflex">
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_1.png"
                        alt=""
                      />学校官网
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_2.png"
                        alt=""
                      />学院设置
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_3.png"
                        alt=""
                      />开设专业
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_4.png"
                        alt=""
                      />历年高考数据
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.teacherInfoList.length > 0"
                  class="item-content-box sflex sflex-ac sflex-wrap"
                >
                  <div
                    v-for="(childItem, i) in item.teacherInfoListNew"
                    :key="i"
                    class="item-centent sflex sflex-ac sflex-jc sflex-y"
                    @click="
                      goToSchoolDetails(2, item.id, childItem.id, item.address)
                    "
                  >
                    <div class="content-name-box">
                      <div class="content-img-box sflex sflex-ac sflex-y">
                        <div class="head-img">
                          <img
                            class="fit-img-cover"
                            :src="
                              childItem.avatar
                                ? childItem.avatar
                                : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="title-img">
                          <img
                            class="fit-img-cover"
                            src="../assets/images/teacher_title.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="school-tea-name">
                        {{ childItem.realName }}{{ childItem.teacherTitle }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.teacherInfoList.length > 8"
                    class="item-more sflex sflex-ac sflex-jc sflex-y"
                    @click="goToSchoolDetails(2, item.id, '', item.address)"
                  >
                    <div class="more-box sflex sflex-ac">
                      <img
                        src="../assets/images/major_more.png"
                        class="more-img"
                        alt=""
                      />
                    </div>
                    <div>更多...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev school-universities-prev"></div>
            <div class="swiper-button-next school-universities-next"></div>
          </div>
        </div>
      </div>
      <!-- 按学校 高校结束 -->
      <!-- 横幅开始 -->
      <div class="major-banner container">
        <img
          class="major-banner-img fit-img-cover"
          src="../assets/images/major_banner2.png"
          alt=""
        />
      </div>
      <!-- 横幅结束 -->
      <!-- 按专业 高职开始 v-if="vocationalTeachersListByMajor.length > 0" -->
      <div
        v-if="vocationalTeachersListByMajor.length > 0"
        class="major-main-box container"
      >
        <div class="main-title sflex sflex-ac sflex-jsp">
          <div class="sflex">
            <img src="../assets/images/major_title1.png" alt="" />
            <div class="title-icon title-icon-orange">高职</div>
          </div>
          <div class="title-more" @click="goToMajorDetails(1)">
            <p>更多</p>
            <img src="../assets/images/major_title_more.png" alt="" />
          </div>
        </div>
        <div class="main-swiper">
          <div class="major-vocational-swiper swiper-1">
            <div class="swiper-wrapper">
              <div
                v-for="(item, d) in vocationalTeachersListByMajor"
                :key="d"
                class="swiper-slide major-item"
              >
                <div
                  class="item-top"
                  @click="
                    gotoDetailsForMajor(item.categoryName, item.parentId, 1, '')
                  "
                >
                  <div class="item-title">
                    {{ item.code }} {{ item.categoryName }}
                    <!-- <span class="text-orange">（高职）</span> -->
                  </div>
                  <div class="sflex">
                    <div class="title-icon">专业</div>
                    <div class="sflex sflex-ac sflex-wrap title-category">
                      <div
                        v-for="(childItem, e) in item.childList"
                        :key="e"
                        class="sflex sflex-ac"
                      >
                        {{ childItem.categoryName }}
                        <div v-if="item.childList.length - 1 > e" class="line">
                          &nbsp;/&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.teacherInfoList.length > 0"
                  class="item-content-box sflex sflex-ac sflex-wrap"
                >
                  <div
                    v-for="(teacherItem, f) in item.teacherInfoListNew"
                    :key="f"
                    class="item-centent"
                    @click="
                      gotoDetailsForMajor(
                        item.categoryName,
                        item.parentId,
                        1,
                        teacherItem.id
                      )
                    "
                  >
                    <div class="content-name-box sflex">
                      <div class="content-img-box sflex sflex-ac sflex-y">
                        <div class="head-img">
                          <img
                            class="fit-img-cover"
                            :src="
                              teacherItem.avatar
                                ? teacherItem.avatar
                                : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="title-img">
                          <img
                            class="fit-img-cover"
                            src="../assets/images/teacher_title.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="sflex-grow">
                        <div class="content-name ut-s2">
                          {{ teacherItem.realName
                          }}{{ teacherItem.teacherTitle }}
                        </div>
                        <div class="sflex sflex-ac content-location">
                          <img
                            src="../assets/images/major_location.png"
                            class="location-icon"
                            alt=""
                          />
                          <div class="ut-s2">
                            {{ teacherItem.provinceName
                            }}{{ teacherItem.cityName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="conetent-rank ut-s2">
                      {{ teacherItem.teacherName }}<br />{{
                        teacherItem.teacherDescribe
                      }}
                    </div>
                  </div>
                  <div
                    v-if="item.teacherInfoList.length > 4"
                    class="item-more sflex sflex-ac sflex-jc"
                    @click="
                      gotoDetailsForMajor(
                        item.categoryName,
                        item.parentId,
                        1,
                        ''
                      )
                    "
                  >
                    <div class="more-box sflex sflex-ac">
                      <img
                        src="../assets/images/major_more.png"
                        class="more-img"
                        alt=""
                      />
                    </div>
                    <div>更多...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev major-vocational-prev"></div>
            <div class="swiper-button-next major-vocational-next"></div>
          </div>
        </div>
      </div>
      <!-- 按专业 高职结束 -->
      <!-- 横幅开始 -->
      <div class="major-banner container">
        <img
          class="major-banner-img fit-img-cover"
          src="../assets/images/major_banner3.png"
          alt=""
        />
      </div>
      <!-- 横幅结束 -->
      <!-- 按学校 高职开始 -->
      <div
        v-if="vocationalTeachersListBySchool.length > 0"
        class="major-main-box container"
      >
        <div class="main-title sflex sflex-ac sflex-jsp">
          <div class="sflex">
            <img src="../assets/images/major_title2.png" alt="" />
            <div class="title-icon title-icon-orange">高职</div>
          </div>
          <div class="title-more" @click="goToSchoolDetails(1, '', '')">
            <p>更多</p>
            <img src="../assets/images/major_title_more.png" alt="" />
          </div>
        </div>
        <div class="main-swiper">
          <div class="school-vocational-swiper swiper-1">
            <div class="swiper-wrapper">
              <div
                v-for="(item, x) in vocationalTeachersListBySchool"
                :key="x"
                class="swiper-slide school-item"
              >
                <div class="school-brief">
                  <div
                    class="item-top sflex"
                    @click="goToSchoolDetails(1, item.id, '', item.address)"
                  >
                    <div class="school-img">
                      <img class="fit-img-cover" :src="item.logo" alt="" />
                    </div>
                    <div class="item-title">
                      <div class="sflex sflex-ae">
                        <div class="name">{{ item.name }}</div>
                        <div class="sflex sflex-ac content-location">
                          <img
                            src="../assets/images/major_location.png"
                            class="location-icon"
                            alt=""
                          />
                          <div class="ut-s2">{{ item.address }}</div>
                        </div>
                      </div>
                      <div class="slogan">
                        校训：<span class="text-blue">{{ item.motto }}</span>
                      </div>
                      <div class="school-rank sflex sflex-ac sflex-wrap">
                        <div
                          v-for="(levelItem, z) in item.levelArr"
                          :key="z"
                          class="rank-item"
                        >
                          {{ levelItem }}
                        </div>
                        <!-- <div class="blue">双一流</div>
                        <div class="pink">985</div>
                        <div class="green">211</div>
                        <div class="purple">公办</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="item-bottom sflex">
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_1.png"
                        alt=""
                      />学校官网
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_2.png"
                        alt=""
                      />学院设置
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_3.png"
                        alt=""
                      />开设专业
                    </div>
                    <div class="bottom-box sflex sflex-ac">
                      <img
                        src="../assets/images/school_icon_4.png"
                        alt=""
                      />历年高考数据
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.teacherInfoList.length > 0"
                  class="item-content-box sflex sflex-ac sflex-wrap"
                >
                  <div
                    v-for="(childItem, y) in item.teacherInfoListNew"
                    :key="y"
                    class="item-centent sflex sflex-ac sflex-jc sflex-y"
                    @click="
                      goToSchoolDetails(1, item.id, childItem.id, item.address)
                    "
                  >
                    <div class="content-name-box">
                      <div class="content-img-box sflex sflex-ac sflex-y">
                        <div class="head-img">
                          <img
                            class="fit-img-cover"
                            :src="
                              childItem.avatar
                                ? childItem.avatar
                                : 'https://zetest1.obs.cn-north-4.myhuaweicloud.com/mp_user/major/logo.png'
                            "
                            alt=""
                          />
                        </div>
                        <div class="title-img">
                          <img
                            class="fit-img-cover"
                            src="../assets/images/teacher_title.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="school-tea-name">
                        {{ childItem.realName }}{{ childItem.teacherTitle }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.teacherInfoList.length > 8"
                    class="item-more sflex sflex-ac sflex-jc sflex-y"
                    @click="goToSchoolDetails(1, item.id, '', item.address)"
                  >
                    <div class="more-box sflex sflex-ac">
                      <img
                        src="../assets/images/major_more.png"
                        class="more-img"
                        alt=""
                      />
                    </div>
                    <div>更多...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev school-vocational-prev"></div>
            <div class="swiper-button-next school-vocational-next"></div>
          </div>
        </div>
      </div>
      <!-- 按学校 高职结束 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Major',
  data() {
    return {
      searchValue: '', // 搜索值
      collegeTeachersListByMajor: [], // 按专业找老师（高校）
      vocationalTeachersListByMajor: [], // 按专业找老师（高职）
      collegeTeachersListBySchool: [], // 按学校找老师（高校）
      vocationalTeachersListBySchool: [], // 按学校找老师（高职）
      dicList: [], // 搜索建议
    }
  },
  mounted() {
    this.getDictDatal('homepage_search_referral')
    // 加载选专业模块所有列表
    this.getAllList()

    // 手机端头部图片轮播
    new Swiper('.major-head-swiper', {
      // loop: true,
      slidesPerView: 'auto',
    })
  },
  methods: {
    gotoDetailsForMajor(name, parentId, subjectType, teacherId) {
      this.$router.push({
        path: '/MajorChooseMajor',
        query: {
          specialityName: name,
          parentId: parentId,
          subjectType: subjectType,
          teacherId: teacherId,
        },
      })
    },
    // 获取选专业所有列表
    getAllList() {
      // 获取按专业、学校找老师（1-专科、 2-本科）
      this.listByTopSpeciality('1')
      this.listByTopSpeciality('2')
      // this.getTeachersListByMajor('1')
      // this.getTeachersListByMajor('2')
      this.getTeachersListBySchool('专科')
      this.getTeachersListBySchool('本科')
    },

    // 获取顶级专业分类
    getDictDatal(str) {
      this.$apis.getDictDatal(str).then((response) => {
        if (response.data.code === 200) {
          this.dicList = response.data.data
          this.dicList.forEach((item) => {
            item.isCheck = false
          })
        }
      })
    },

    // 点击搜索建议
    getDicInfo(dictValue) {
      this.searchValue = dictValue
      this.dicList.forEach((item) => {
        item.isCheck = false
        if (item.dictValue === dictValue) {
          item.isCheck = true
        }
      })
      this.getAllList()
    },

    // 获取顶级专业分类
    listByTopSpeciality(subjectType) {
      let obj = {
        subjectType: subjectType, // 类型（1-专科、 2-本科）
      }
      this.$apis.listByTopSpeciality(obj).then((response) => {
        if (response.data.code === 200) {
          let arr = response.data.rows
          this.collegeTeachersListByMajor = []
          this.vocationalTeachersListByMajor = []
          let arrLength = arr.length
          for (let i = 0; i < arr.length; i++) {
            this.getTeachersListByMajor(arr[i].id, subjectType, arrLength, i)
          }
        }
        // this.getTeachersListByMajor('1')
        // this.getTeachersListByMajor('2')
      })
    },

    // 按专业找老师
    getTeachersListByMajor(id, subjectType, length, index) {
      let obj = {
        status: 1,
        // pageNum: 1,
        // pageSize: 1000,
        parentId: id, // 搜索值
        // searchValue: this.searchValue, // 搜索值
        subjectType: subjectType, // 类型（1-专科、 2-本科）
      }
      if (this.searchValue.indexOf('大学') !== -1) {
        obj.collegeId = this.searchValue
      } else {
        obj.searchValue = this.searchValue
      }
      this.$apis.getTeachersListByMajor(obj).then((response) => {
        if (response.data.code === 200) {
          if (subjectType === '1') {
            let vocationalArr = response.data.rows
            vocationalArr.forEach((item) => {
              if (item.teacherInfoList != null) {
                if (item.teacherInfoList.length > 4) {
                  item.teacherInfoListNew = this.getRandomArrayElements(
                    item.teacherInfoList,
                    3
                  )
                } else if (item.teacherInfoList.length > 1) {
                  item.teacherInfoListNew = this.getRandomArrayElements(
                    item.teacherInfoList,
                    item.teacherInfoList.length
                  )
                } else if (item.teacherInfoList.length === 1) {
                  item.teacherInfoListNew = item.teacherInfoList
                }
              }
            })

            this.vocationalTeachersListByMajor.push(...vocationalArr)

            if (length - 1 === index) {
              setTimeout(() => {
                this.vocationalTeachersListByMajor =
                  this.vocationalTeachersListByMajor.sort(
                    this.compare('teacherInfoList')
                  )
                this.$nextTick(() => {
                  // 按专业找老师（高职）
                  new Swiper('.major-vocational-swiper', {
                    // loop: true,
                    slidesPerView: 'auto',
                    // 如果需要前进后退按钮
                    nextButton: '.major-vocational-next',
                    prevButton: '.major-vocational-prev',
                  })
                })
              }, 1500)
            }
          } else if (subjectType === '2') {
            let collegeArr = response.data.rows

            collegeArr.forEach((item) => {
              if (item.teacherInfoList) {
                item.teacherInfoListNew = []
                // item.teacherInfoListNew = item.teacherInfoList
                if (item.teacherInfoList.length > 4) {
                  // for (let i = 0; i < 4; i++) {
                  // let j = Math.round(Math.random() * item.teacherInfoList.length);
                  // item.teacherInfoListNew = item.teacherInfoList.slice(0, 3)
                  // item.teacherInfoListNew.push(item.teacherInfoList[j])
                  // }
                  item.teacherInfoListNew = this.getRandomArrayElements(
                    item.teacherInfoList,
                    3
                  )
                } else if (item.teacherInfoList.length > 1) {
                  item.teacherInfoListNew = this.getRandomArrayElements(
                    item.teacherInfoList,
                    item.teacherInfoList.length
                  )
                  // for (let i = 0; i < item.teacherInfoList.length; i++) {
                  //   let j = Math.round(Math.random() * item.teacherInfoList.length);
                  //   console.log(j)
                  // item.teacherInfoListNew = item.teacherInfoList.slice(0, 3)
                  // item.teacherInfoListNew.push(item.teacherInfoList[j])
                  // }
                } else if (item.teacherInfoList.length === 1) {
                  item.teacherInfoListNew = item.teacherInfoList
                }
              }
            })

            this.collegeTeachersListByMajor.push(...collegeArr)

            if (length - 1 === index) {
              setTimeout(() => {
                this.collegeTeachersListByMajor =
                  this.collegeTeachersListByMajor.sort(
                    this.compare('teacherInfoList')
                  )
                this.$forceUpdate()
                this.$nextTick(() => {
                  // 按专业找老师（高校）
                  new Swiper('.major-universities-swiper', {
                    // loop: true,
                    slidesPerView: 'auto',
                    // 如果需要前进后退按钮
                    nextButton: '.major-universities-next',
                    prevButton: '.major-universities-prev',
                  })
                })
              }, 1500)
            }
          }
        }
      })
    },

    // 随机取数
    getRandomArrayElements(arr, count) {
      let shuffled = arr.slice(0)
      let i = arr.length
      let min = i - count
      let temp
      let index
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random())
        temp = shuffled[index]
        shuffled[index] = shuffled[i]
        shuffled[i] = temp
      }
      return shuffled.slice(min)
    },

    // 按专业选老师 根据教师数量从大到小排序
    compare(property) {
      return function (a, b) {
        let value1 = a[property].length
        let value2 = b[property].length
        return value2 - value1
      }
    },

    // 按学校找老师
    getTeachersListBySchool(subjectType) {
      let obj = {
        pageNum: 1,
        pageSize: 1000,
        // searchValue: this.searchValue, // 搜索值
        nature: subjectType, // 类型（1-专科、 2-本科）
      }
      if (this.searchValue.indexOf('大学') !== -1) {
        obj.collegeId = this.searchValue
      } else {
        obj.searchValue = this.searchValue
      }
      this.$apis.getTeachersListBySchool(obj).then((response) => {
        if (response.data.code === 200) {
          let arr = response.data.rows
          arr.forEach((item) => {
            if (item.level !== null && item.level !== '') {
              if (item.level.indexOf(',') !== -1) {
                item.levelArr = item.level.split(',')
              } else {
                let levelArr = []
                levelArr.push(item.level)
                item.levelArr = levelArr
              }
            }
          })
          arr.forEach((item, index) => {
            arr[index].teacherInfoListNew = item.teacherInfoList
            if (item.teacherInfoList.length > 8) {
              arr[index].teacherInfoListNew = item.teacherInfoList.slice(0, 7)
            }
          })
          if (subjectType === '专科') {
            this.vocationalTeachersListBySchool = arr
            this.$nextTick(() => {
              // 按学校找老师（高职）
              new Swiper('.school-vocational-swiper', {
                // loop: true,
                slidesPerView: 'auto',
                // 如果需要前进后退按钮
                nextButton: '.school-vocational-next',
                prevButton: '.school-vocational-prev',
              })
            })
          } else if (subjectType === '本科') {
            this.collegeTeachersListBySchool = arr
            this.$nextTick(() => {
              // 按学校找老师（高校）
              new Swiper('.school-universities-swiper', {
                // loop: true,
                slidesPerView: 'auto',
                // 如果需要前进后退按钮
                nextButton: '.school-universities-next',
                prevButton: '.school-universities-prev',
              })
            })
          }
        }
      })
    },

    goToMajorDetails(subjectType) {
      this.$router.push({
        path: '/MajorChooseMajor',
        query: { subjectType: subjectType },
      })
    },
    goToSchoolDetails(subjectType, collegeId, teacherId, address = '') {
      let addressStr = ''
      if (address !== '') {
        let arr = address.split(',')
        addressStr = arr[0]
      }
      this.$router.push({
        path: '/MajorChooseSchool',
        query: {
          subjectType: subjectType,
          collegeId: collegeId,
          teacherId: teacherId,
          address: addressStr,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.major-wrap {
  background: #f6f7f9;
  .p-head-bottom {
    display: block;
  }
  .m-head-bottom {
    display: none;
  }
  .major-modal {
    background: url(../assets/images/major_bg.png) no-repeat;
    background-size: 100%;
    .container {
      margin: 0 14%;
    }
    .head-top {
      margin-bottom: 1rem;
    }
    .major-banner {
      padding: 0.5rem 0 1rem;
    }
    .major-main-box {
      .swiper-1 {
        // 按专业
        .major-item.swiper-slide {
          width: 38%;
          margin-right: 1rem;
        }
        .major-item {
          color: #666666;
          font-size: 0.75rem;
          text-align: left;
          .item-top {
            height: 7.25rem;
            border-radius: 0.35rem;
            background: #fff;
            margin-bottom: 1px;
            padding: 0.8rem 0.85rem 0.55rem;
            .item-title {
              font-size: 0.9rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              margin-bottom: 0.45rem;
              cursor: pointer;
            }
            .title-icon {
              background: url(../assets/images/major_title.png);
              background-size: 100% 100%;
              line-height: 1.25rem;
              width: 2.4rem;
              min-width: 2.4rem;
              height: 1.25rem;
              color: #ffffff;
              font-size: 0.75rem;
              padding-left: 0.3rem;
              margin-right: 0.5rem;
            }
            .title-category {
              max-height: 3.5rem;
              overflow: hidden;
              overflow-y: auto;
            }
            /* 设置滚动条的样式 */
            .title-category::-webkit-scrollbar {
              width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 1px;
            }
            /* 滚动槽 */
            .title-category::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 5px rgb(255, 249, 249);
              border-radius: 5px;
              background: #ebe9e9;
            }
            /* 滚动条滑块 */
            .title-category::-webkit-scrollbar-thumb {
              border-radius: 5px;
              // -webkit-box-shadow: inset 0 0 5px rgb(255, 255, 255);
              background: #455ef7;
            }
            .title-category::-webkit-scrollbar-thumb:window-inactive {
              background: #455ef7;
              // box-shadow: rgba(255, 0, 0, 0.4);
            }
            .line {
              color: #455ef7;
              font-size: 0.9rem;
              line-height: 1.25rem;
              // margin-top: 0.2rem;
            }
          }
          .item-content-box {
            .item-centent {
              background: #fff;
              width: 50%;
              height: 9.15rem;
              padding: 0.7rem 1rem;
              box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.02);
              border-radius: 0.35rem;
              margin-bottom: 1px;
              .content-name-box {
                .content-img-box {
                  margin-right: 0.8rem;
                  .head-img {
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid #455ef7;
                  }
                  .title-img {
                    width: 4.25rem;
                    height: 1.2rem;
                    margin-top: 0;
                    z-index: 2;
                  }
                }
                .content-name {
                  font-size: 0.8rem;
                  font-weight: bold;
                  color: #333333;
                  // line-height: 1.5rem;
                  margin: 0.3rem 0;
                }
                .content-location {
                  font-size: 0.7rem;
                  color: #a0a1a3;
                  .location-icon {
                    width: 0.6rem;
                    height: 0.7rem;
                    margin-right: 0.25rem;
                  }
                }
              }
              .conetent-rank {
                font-size: 0.65rem;
                line-height: 1.2rem;
                margin-top: 0.3rem;
              }
            }
            .item-centent:nth-child(2n + 1) {
              width: calc(50% - 1px);
              margin-right: 1px;
            }
            .item-more {
              background: #fff;
              width: 50%;
              height: 9.15rem;
              box-sizing: content-box;
              box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.02);
              border-radius: 0.35rem;
              .more-box {
                width: 3.75rem;
                height: 3.75rem;
                border: 2px solid #bbc6fd;
                background: #d9dffe;
                border-radius: 50%;
                margin-right: 0.7rem;
                .more-img {
                  width: 2.95rem;
                  height: 1.85rem;
                  margin-left: 0.4rem;
                }
              }
            }
          }
        }
        // 按学校
        .school-item {
          width: calc((100% - 1rem) / 2);
          margin-right: 1rem;
          .school-brief {
            border-radius: 0.35rem;
            background: #fff;
            padding: 1.1rem 0.8rem 0.9rem;
            text-align: left;
            .item-top {
              border-bottom: 1px solid #eeeff2;
              padding-bottom: 0.95rem;
              cursor: pointer;
              .school-img {
                width: 4.8rem;
                height: 4.8rem;
                margin-right: 1.2rem;
              }
              .item-title {
                font-size: 0.7rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #787878;
                .name {
                  font-size: 0.9rem;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #333333;
                  line-height: 1.5rem;
                }
                .content-location {
                  margin-bottom: 0.1rem;
                }
                .location-icon {
                  width: 0.6rem;
                  height: 0.7rem;
                  margin: 0 0.35rem 0 0.7rem;
                }
                .slogan {
                  font-size: 0.8rem;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                  margin: 0.3rem 0 0.7rem;
                }
                .school-rank {
                  max-height: 1.95rem;
                  min-height: 1.95rem;
                  height: 1.95rem;
                  overflow: hidden;
                  overflow-y: auto;
                  .rank-item {
                    // width: 3.3rem;
                    text-align: center;
                    padding: 0 0.5rem;
                    height: 1.45rem;
                    line-height: 1.45rem;
                    border-radius: 0.25rem;
                    margin-right: 0.55rem;
                    margin-bottom: 0.5rem;
                  }
                  .rank-item:nth-child(5n + 1) {
                    background: #fff5e5;
                    color: #fb8001;
                  }
                  .rank-item:nth-child(5n + 2) {
                    background: #eaebfa;
                    color: #4861f5;
                  }
                  .rank-item:nth-child(5n + 3) {
                    background: #fef1eb;
                    color: #ee5681;
                  }
                  .rank-item:nth-child(5n + 4) {
                    background: #e4f3f6;
                    color: #2bbec8;
                  }
                  .rank-item:nth-child(5n + 5) {
                    background: #f2e3f6;
                    color: #9c50b7;
                  }
                }
                /* 设置滚动条的样式 */
                .school-rank::-webkit-scrollbar {
                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
                  height: 1px;
                }
                /* 滚动槽 */
                .school-rank::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 5px rgb(255, 249, 249);
                  border-radius: 5px;
                  background: #ebe9e9;
                }
                /* 滚动条滑块 */
                .school-rank::-webkit-scrollbar-thumb {
                  border-radius: 5px;
                  // -webkit-box-shadow: inset 0 0 5px rgb(255, 255, 255);
                  background: #455ef7;
                }
                .school-rank::-webkit-scrollbar-thumb:window-inactive {
                  background: #455ef7;
                  // box-shadow: rgba(255, 0, 0, 0.4);
                }
              }
            }
            .item-bottom {
              margin-top: 0.85rem;
              font-size: 0.75rem;
              color: #555555;
              .bottom-box {
                margin-right: 1rem;
              }
              img {
                width: 0.85rem;
                height: 0.85rem;
                margin-right: 0.25rem;
              }
            }
          }
          .item-content-box {
            border-radius: 0.35rem;
            background: #fff;
            margin-top: 1px;
            .item-centent {
              width: 25%;
              height: 9.15rem;
              font-size: 0.8rem;
              font-weight: bold;
              color: #555555;
              .content-img-box {
                margin-bottom: 0.25rem;
                .head-img {
                  width: 4rem;
                  height: 4rem;
                  border-radius: 50%;
                  overflow: hidden;
                  border: 1px solid #455ef7;
                }
                .title-img {
                  width: 4.25rem;
                  height: 1.2rem;
                  margin-top: 0;
                  z-index: 2;
                }
              }
            }
            .item-more {
              background: #fff;
              width: 25%;
              height: 8.35rem;
              .more-box {
                width: 3.75rem;
                height: 3.75rem;
                border: 2px solid #bbc6fd;
                background: #d9dffe;
                border-radius: 50%;
                margin-right: 0.7rem;
                margin-bottom: 0.4rem;
                .more-img {
                  width: 2.95rem;
                  height: 1.85rem;
                  margin-left: 0.4rem;
                }
              }
            }
          }
        }
        .school-item:last-child {
          margin-right: 0;
        }
      }
    }
    .major-main-box:last-child {
      padding-bottom: 1.5rem;
    }
    .major-banner {
      .major-banner-img {
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .major-wrap {
    .major-modal {
      .major-head-box {
        .head-top {
          .head-right {
            width: 20rem;
            .search-content-box {
              max-height: 10rem;
              overflow-x: hidden;
              overflow-y: auto;
            }
            .search-content-box::-webkit-scrollbar {
              width: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .major-wrap {
    .major-modal {
      .container {
        margin: 0 8%;
      }
      .major-main-box {
        .swiper-1 {
          .swiper-slide {
            width: 60%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .major-wrap {
    .major-modal {
      .container {
        margin: 0 4%;
      }
      .major-head-box {
        .head-top {
          .head-right {
            padding: 0.8rem 1rem 0.5rem 1rem;
          }
          .major-tip-box {
            margin: 0.8rem 0 0.7rem !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .major-wrap {
    .major-modal {
      .container {
        margin: 0 4%;
      }
      .major-main-box {
        .swiper-1 {
          .swiper-slide {
            width: 70%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .major-wrap {
    .p-head-bottom {
      display: none;
    }
    .major-modal {
      .container {
        margin: 0 4%;
      }
      .major-head-box {
        .head-top {
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;
          .head-left {
            border-radius: 0.5rem;
            margin-right: 0 !important;
          }
          .head-right {
            width: 100% !important;
            margin-top: 0.5rem;
          }
        }
      }
      .major-main-box {
        .swiper-1 {
          .major-item.swiper-slide {
            width: 80% !important;
          }
          .school-item.swiper-slide {
            width: 100% !important;
          }
        }
      }
    }
    .m-head-bottom {
      display: block;
      .major-head-swiper {
        overflow: hidden;
        .swiper-slide {
          // width: 75%;
          width: auto;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
