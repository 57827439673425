<template>
  <div class="about">
    <div class="about-title">
      <img src="../assets/images/about_title.png" />
    </div>
    <div class="about-content">
      <div class="about-content-title sflex">
        <div class="about-content-title-line"></div>
        <div>概述</div>
      </div>
      <div class="about-content-label">
        北京择而胜教育咨询平台（北京择而胜教育科技有限责任公司所有），是由数百名高校教师、博士后、博士真人实名上线，以强烈的社会责任感和使命感，共创共建的高考、考研专业咨询指导服务联盟。
        我们秉承选择与努力同等重要的理念，旨在通过专业、理性、精准的咨询指导，为高中-大学的教育衔接补上重要一环，帮助学生及家长尽早打破信息壁垒
        ，实现专业的理性选择与志愿的科学填报，为当下报考、成长成才及未来发展奠定坚实基础。
      </div>
      <div class="about-content-label1">
        深刻洞察“专业选择”与“个人发展”“个人幸福”的紧密联系，从学生长远发展出发谋篇布局；<br />
        深刻体会学生及家长在专业选择方面“重视但无助”
        “有心却无力”的焦虑急迫，想你所想，急你所急。
      </div>

      <div class="about-content-label2 sflex sflex-ac sflex-jsp">
        <div class="item">
          <img class="item-radius1" src="../assets/images/about_2.png" alt="" />
        </div>
        <div class="item">
          <img class="item-radius2" src="../assets/images/about_1.png" alt="" />
        </div>
      </div>

      <!-- <div class="about-content-title about-content-title-work sflex">
        <div class="about-content-title-line"></div>
        <div>工作动态</div>
        <div class="about-more">更多></div>
      </div>
      <div class="about-conten-pic-text sflex sflex-jsp">
        <div class="item">
          <div class="item-img">
            <img src="../assets/images/about_pic_text.png" />
          </div>
          <div class="item-time">2021-02-13</div>
          <div class="item-title">
            2020年4月10日RFA与RFA-FTT项目正式落户浙江省杭州市
          </div>
          <div class="item-label">
            大家讲讲报考教育学，到底应该考学硕还是专硕呢？教育学考研到底选择专硕还是学硕啊？两者有什么区别吗？
          </div>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="../assets/images/about_pic_text.png" />
          </div>
          <div class="item-time">2021-02-13</div>
          <div class="item-title">
            2020年4月10日RFA与RFA-FTT项目正式落户浙江省杭州市
          </div>
          <div class="item-label">
            大家讲讲报考教育学，到底应该考学硕还是专硕呢？教育学考研到底选择专硕还是学硕啊？两者有什么区别吗？
          </div>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="../assets/images/about_pic_text.png" />
          </div>
          <div class="item-time">2021-02-13</div>
          <div class="item-title">
            2020年4月10日RFA与RFA-FTT项目正式落户浙江省杭州市
          </div>
          <div class="item-label">
            大家讲讲报考教育学，到底应该考学硕还是专硕呢？教育学考研到底选择专硕还是学硕啊？两者有什么区别吗？
          </div>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="../assets/images/about_pic_text.png" />
          </div>
          <div class="item-time">2021-02-13</div>
          <div class="item-title">
            2020年4月10日RFA与RFA-FTT项目正式落户浙江省杭州市
          </div>
          <div class="item-label">
            大家讲讲报考教育学，到底应该考学硕还是专硕呢？教育学考研到底选择专硕还是学硕啊？两者有什么区别吗？
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
}
</script>

<style lang="less" >
.about {
  width: 100vw;
  .about-title {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .about-content {
    margin: 2.35rem auto 0 auto;
    width: 78%;
    text-align: left;

    .about-content-title {
      align-items: center;
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 0.9rem;
      .about-content-title-line {
        width: 0.4rem;
        height: 1.5rem;
        border-radius: 0.17rem;
        background: #5b76e0;
        margin-right: 0.6rem;
      }
    }

    .about-content-label {
      font-size: 0.9rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 1.8rem;
      margin-top: 1.65rem;
      text-indent: 2rem;
    }
    .about-content-label1 {
      background: url(../assets/images/about_bg.png) no-repeat;
      background-size: 100% 100%;
      padding: 1.75rem 1.8rem;
      font-size: 1.15rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 2rem;
      border-radius: 0.5rem;
      margin-top: 1.65rem;
    }
    .about-content-label2 {
      margin-top: 1.55rem;
      .item {
        width: calc(50% - 1.5rem);

        // height: 7.8rem;
      }
      .item-radius1 {
        border-radius: 5rem 5rem 0 5rem;
        box-shadow: 0rem 0rem 1rem 0rem rgba(159, 165, 190, 0.29);
        overflow: hidden;
      }
      .item-radius2 {
        float: right;
        overflow: hidden;
        border-radius: 5rem 5rem 5rem 0;
        box-shadow: 0rem 0rem 1rem 0rem rgba(159, 165, 190, 0.29);
      }
    }

    .about-content-title-work {
      margin-top: 1.55rem;
      position: relative;
      .about-more {
        position: absolute;
        width: 2.2rem;
        height: 0.75rem;
        font-size: 0.8rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #787878;
        line-height: 0.9rem;
        right: 0;
        bottom: 0;
      }
    }
    .about-conten-pic-text {
      margin-top: 1.7rem;
      margin-bottom: 1.6rem;
      img {
        width: 100%;
      }
      .item {
        width: calc(25% - 0.63rem);
        margin-right: 0.85rem;
        padding: 0.65rem 0.65rem 1.3rem 0.65rem;
        border: 0.05px solid #dddddd;
        border-radius: 0.4rem;
        .item-time {
          margin-top: 0.95rem;
          font-size: 0.7rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c7c7c7;
        }
        .item-title {
          margin-top: 0.95rem;
          font-size: 0.9rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 1.3rem;
          text-indent: 2rem;
        }
        .item-label {
          margin-top: 0.95rem;
          font-size: 0.8rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a09f9f;
          line-height: 1.2rem;
        }
      }
      .item:last-child {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .about {
    .about-content {
      .about-content-label2 {
        .item {
          width: calc(50% - 0.5rem);
        }
      }
      .about-conten-pic-text {
        flex-wrap: wrap;
        .item {
          width: calc(50% - 0.5rem);
          margin-bottom: 0.95rem;
        }
        .item:nth-child(2n) {
          margin-right: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .about {
    .about-content {
      .about-content-label2 {
        flex-direction: column;
        .item {
          width: 100%;
        }
        .item:nth-child(2) {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>
